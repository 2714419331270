
/*** Site Header Styles ***/

#site-header {
	background-color: $white;
	position: relative;
	top: 0;
	z-index: 15;
	height: 100px;
	padding: 15px 0;
	@include transition(top .3s ease);


	.row {
		display: flex;
		align-items: center;
		position: relative;

		.logos {
			display: flex;
			align-items: center;
			width: 50%;
			flex-direction: row;

			@media (min-width: 916px) {
				width: 20%;
			}
		}
	}

	.eu-logo {
		width: 40px;
		margin: 0 8px;

		@media (min-width: 430px) {
			margin: 0 0 0 16px;
			width: 50px;
		}

		@media (min-width: 916px) {
			width: 60px;
		}
	}

	.header-logo {
		img {
			max-width: 120px;
			float: left;
		}
	}

	[class^=span] {
		text-align: right;
	}

	.main-navigation,
	.search-ico,
	.menu-toggle {
		display: inline-block;
	}

	.main-navigation {
		margin-top: 25px;
		position: relative;
		width: 100%;
		max-width: 760px;

		ul {
			list-style-type: none;
			margin: 0;
			text-align: right;

			&.child-sub-nav {
				background-color: #1b434d;
				position: absolute;
				top: 0;
				right: 0;
				text-align: left;
				width: 50%;
				height: 100%;
				bottom: 0;
				padding: 20px 0;
				@include transition(all .3s ease);

				& > li {
					background-color: #1b434d;
					padding: 10px 20px 10px;

					a {
						&:after {
							content: none !important;
						}

						&:hover {
							text-decoration: underline;
						}
					}

					&:hover {
						a {
							text-decoration: underline;

							&:after {
								content: none;
								display: none;
							}
						}
					}
				}
			}

			&.sub-nav {
				position: absolute;
				width: 540px;
				background-color: $navy;
				text-align: left;
				padding: 20px;
				top: 45px;
				left: 0;
				z-index: 15;

				li:first-child {
					margin-left: 0;
				}

				& > li {
					color: $white;
					width: 50%;
					padding: 9px 20px 9px 0;
					position: static;
					@include font-size(17);
					letter-spacing: 0.1px;
					@include Din(bold);

					&:before,
					&:after {
						content: none !important;
					}

					.arrow {
						display: none;
					}

					a {
						display: block;
						width: 100%;

						&:after {
							content: none;
						}

						&:hover {
							text-decoration: underline;
						}
					}

					&.has-child {
						a {
							display: inline-block;
							width: 98%;
							position: relative;
							padding-right: 12px;
							white-space: normal;

							&:after {
								background-color: transparent;
							}
						}

						.arrow {
							position: absolute;
							top: 50%;
							right: 0;
							display: block;
							border-right: 2px solid white;
							border-bottom: 2px solid white;
							@include transform(rotate(-45deg) translateY(-50%));
							width: 9px;
							height: 9px;
						}

						&:after {
							content: none;
						}

						&:hover,
						&:focus-within {
							.child-sub-nav {
								display: flex;
								flex-direction: column;
								left: auto;
								right: 0;

								li {
									width: 100%;
									color: $white;
								}
							}
						}
					}
				}
			}

			li {
				display: flex;
				color: $grey-3;
				@include Din(demi);
				@include font-size(16);
				padding-right: 30px;
				padding-bottom: 30px;
				visibility: visible;

				a {
					color: inherit;
					text-decoration: none;
					display: inline-block;
					white-space: normal;

					&.top-level {
						white-space: nowrap;
					}
				}

				& > a {
					&:after {
						content: '';
						width: 0;
						display: block;
						margin: 3px auto 0;
						height: 0;
						@include border-radius(3px);
						background-color: var(--turquoise);
						@include transition(all .3s ease);
					}

					&:hover,
					&:focus {
						&:after {
							width: 75%;
							height: 3px;
						}
					}
				}

				&:after {
					content: none;
				}

				&.current-page {
					& > a {
						&:after {
							width: 75%;
							height: 3px;
						}
					}
				}

				&.has-child {



					&:after {
						content: '';
						display: inline-block;
						width: 6px;
						height: 6px;
						border-right: 2px solid $grey-3;
						border-bottom: 2px solid $grey-3;
						@include transform(rotate(45deg));
						position: relative;
						top: 5px;
						margin-left: 10px;
					}

					&:hover,
					&:focus-within {
						&:before {
							display: block;
						}

						ul.sub-nav {
							display: flex;
							flex-direction: column;

							&:before {
								content: '\f0d8';
								font-family: 'FontAwesome';
								position: absolute;
								top: -26px;
								left: 40px;
								color: $navy;
								@include font-size(40,40);
								display: block;
							}
						}
					}
				}
			}
		}
	}

	.search {
		position: absolute;
		display: none;
		width: calc(100% - 270px);
		height: 50px;
		top: -100px;
		left: 0;
		@include transition(all .3s ease-in-out);

		.submit-container {
			position: absolute;
			top: 14px;
			right: 30px;
			display: block;
			width: 21px;
			height: 21px;

			input[type="submit"] {
				color: $navy;
				background: transparent;
				display: block;
				width: 100%;
				height: 100%;
				text-indent: -2000px;
			}

			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-image: url("../images/icons/search-grey.png");
				pointer-events: none;
				background-size: cover;
			}
		}

		&.active {
			display: block;
			z-index: 12;
			top: 10px;
		}
	}

	.search-ico {
		font-size: 0;
		line-height: 0;
		color: transparent;
		padding-right: 50px;
		background-image: url('../images/icons/search-grey.png');
		background-repeat: no-repeat;
		width: 21px;
		height: 24px;
		background-position: 0 3px;
		padding-top: 5px;
		position: relative;
		/*top: 6px;*/
		transition: transform .25s;
		will-change: translate, opacity;

		&:hover,
		&:focus {
			@include translatey(-3px);
			opacity: 0.8;
		}

		&.active {
			background-image: url('../images/icons/close-grey.png');
		}
	}

	.button {
		margin-right: 5px;
	}

	.menu-button {
		position: relative;
		@include inline-flex();
		@include flex-align(center);
		/*top: 2px;*/
		width: 108px;
		margin-left: 5px;
		height: 44px;
		padding: 0 14px;
		border: 3px solid $grey-3;
		border-radius: 5px;
		cursor: pointer;
		transition: border-color 0.25s ease;

		@media (max-width: 1100px) and (min-width: 916px) {
			padding: 0 4px;
			width: 88px;
		}

		@include max-mobile() {
			height: 41px;
			padding: 0 7px;
			width: 94px;
		}

		&:hover,
		&:focus {
			border-color: var(--turquoise);

			.menu-button-text {
				color: var(--turquoise);
			}

			.menu-toggle {
				span {
					width: 12px;
					background-color: var(--turquoise);

					&:nth-of-type(2) {
						width: 18px;
					}

					&:nth-of-type(3) {
						width: 100%;
					}
				}
			}
		}
	}

	.menu-button-text {
		position: relative;
		top: -1px;
		display: inline-block;
		@include Bree(bold-italic);
		@include font-size(18);
		color: $grey-2;
		margin-left: 8px;
		transition: color 0.25s ease;
	}

	.menu-toggle {
		width: 21px;
		position: relative;

		span {
			display: block;
			width: 100%;
			height: 4px;
			background-color: $grey-3;
			@include border-radius(2px);
			margin-bottom: 3px;
			transition: background-color 0.25s ease, width 0.25s ease;

			&:nth-of-type(2) {
				width: 18px;
			}

			&:nth-of-type(3) {
				width: 12px;
				margin-bottom: 0;
			}
		}
	}

	.side-menu {
		position: fixed;
		top: 0;
		right: -30%;
		width: 30%;
		height: 100vh;
		bottom: 0;
		background-color: rgba(2, 47, 58, 0.9);
		z-index: 99;
		padding: 40px 30px;
		opacity: 0;
		@include transition(all .3s ease);
		overflow-y: auto;
		overflow-x: hidden;
		visibility: hidden;

		.button {
			display: none;
			margin-bottom: 30px;
		}

		&.active,
		&:focus-within {
			right: 0;
			opacity: 1;
			visibility: visible;
		}

		.side-navigation {
			width: 100%;
			max-width: 320px;
		}

		.arrow {
			display: none;
			padding: 0 0 0 40px;

			i {
				display: block;
				width: 8px;
				height: 8px;
				margin-top: 8px;
				margin-right: 2px;
				border-right: 1px solid #fff;
				border-bottom: 1px solid #fff;
				@include transform(rotate(-45deg));
				float: right;
			}
		}

		ul.back {
			li {
				@include Din(bold);
				@include font-size(18);
				color: #7c8c91;
				padding-bottom: 20px;
			}
		}

		.close-menu {
			display: block;
			text-align: right;
			cursor: pointer;
			height: 15px;
			float: right;
			margin-top: 8px;

			i {
				color: white;
				width: 15px;
				height: 15px;
				background: url('../images/icons/close-menu.png');
				background-size: cover;
				display: block;
				float: right;
				@include transition(all .3s ease);
			}

			&:hover,
			&:focus {

				i {
					box-shadow: 0 2px 0 0 white;
					@include transform(scale(1.1));
				}
			}
		}

		.mobile-navigation {
			display: none;
		}

		h3 {
			color: white;
			@include Bree(bold);
			@include font-size(24);
			margin-bottom: 25px;
		}

		.quick-links {

			ul {
				list-style-type: none;
				margin: 0 0 30px 0;
				padding: 0;

				li {
					@include Din(extra-bold);
					@include font-size(16);
					padding-bottom: 15px;

					a {
						width: auto;
						color: $white;
						text-decoration: none;

						&:hover,
						&:focus {
							text-decoration: underline;
						}
					}

					&:last-of-type {
						padding-bottom: 0;
					}
				}

				&.sub-links {
					margin: 0 0 40px 0;

					li {
						@include Din(regular);
					}
				}
			}
		}

		.side-social {

			h3 {
				@include font-size(19);
				margin-bottom: 20px;
			}

			a {
				color: $white;
				text-decoration: none;
				@include font-size(27);
				margin-right: 22px;

				i {
					@include transition(transform .25s);
				}

				&:hover,
				&:focus {

					i {
						@include translatey(-5px);
					}
				}
			}
		}
	}

	&.fixed {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99;
		height: 80px;
		@include box-shadow(0, 1px, 4px, 0, #d7d7d7);

		.main-navigation {
			margin-top: 14px;
		}

		.header-logo {
			img {
				max-width: 90px;
				margin-top: 0px;
			}
		}

		.search {
			&.active {
				top: 0;
			}
		}

		~ .career-listing .career-alphabet {
			top: 80px;
		}
	}

	&.hide-element {
		top: -80px !important;

		~ .career-listing .career-alphabet {
			top: 0;
		}
	}

	&.persistant-nav-mobile {
		z-index: 10;
		position: fixed;
		@include transition(top 0.2s ease-in-out);
		width: 100%;

		&.mobile-visible {
			top: 0 !important;
			z-index: 10;
		}
	}

	@include max-desktop() {
		padding: 15px 8px;

		.row {
			position: static;
		}

		.main-navigation {
			ul {
				li {
					padding-right: 15px;

					&.has-child {
						&:after {
							margin-left: 3px;
						}
					}
				}
			}
		}

		.side-menu .button {
			display: inline-block;
		}
	}

	@include max-mobile-nav() {
		.button {
			display: none;
		}

		.search {
			@include calc(width, '100% - 95px');
		}

		.main-navigation {
			ul.sub-nav {
				/*left: -220px;*/
			}
		}
	}

	@media only screen and (max-width: 915px) {

		&.fixed {
			.search-ico {
				margin-top: 10px;
			}
		}

		.button {
			display: inline-block;
		}

		.main-navigation {
			display: none;
		}

		.search-ico {
			width: 21px;
			height: 24px;
			background-position: 0 3px;
			padding-top: 5px;
			/*top: 7px;*/

			&:hover,
			&:focus {
				@include transform(none);
				opacity: 1;
			}
		}

		.search {
			width: calc(100% - 230px);

			&.active {
				top: 17px;
			}

			input[type="search"] {
				@include placeholder-color(#8d989c);
			}
		}

		.side-menu {
			background-color: $navy;
			padding-top: 10px;
			min-width: 320px;
			right: -320px;

			.side-logo {
				display: inline-block;
				margin-bottom: 40px;
				position: relative;
				z-index: 12;

				img {
					width: 100px;
				}
			}

			.close-menu {
				display: inline-block;
				// width: 60%;
				position: relative;
				z-index: 12;
			}

			.sub-panel {
				position: fixed;
				top: 0;
				right: -320px;
				width: 320px;
				height: 100vh;
				background-color: #1b434d;
				z-index: 11;
				padding-top: 100px;
				overflow: auto;
				@include transition(all .3s ease);

				&.active,
				&:focus-within {
					right: 0;

					> ul {
						padding-bottom: 15px;
					}
				}

				ul {
					padding: 0 40px;
					margin: 0 0 20px 0;

					li {
						@include Din(demi);
						@include font-size(18);
					}

					&.back {
						li {
							padding-bottom: 20px;
							cursor: pointer;

							&:before {
								content: '';
								width: 7px;
								height: 7px;
								border-left: 1px solid #7c8c91;
								border-bottom: 1px solid #7c8c91;
								@include transform(rotate(45deg));
								display: inline-block;
								margin-right: 3px;
								position: relative;
								top: -2px;
							}
						}
					}
				}

				&.child {
					background-color: #355d67;
				}
			}

			.mobile-navigation {
				display: block;
				padding-left: 20px;

				ul {
					margin: 0;
					list-style-type: none;

					li {
						margin-bottom: 20px;

						a {
							@include Din(demi);
							@include font-size(20); // Should be 18px but rem value isn't calculated correctly
							color: white;
							text-decoration: none;
						}

						&.has-child {
							display: inline-block;
							width: 100%;

							a {
								display: inline-block;
							}

							.arrow {
								display: block;
								float: right;
							}
						}
					}
				}
			}

			.quick-link {
				@include Din(demi);
				@include font-size(20); // Should be 18px but rem value isn't calculated correctly
				color: white;
				text-decoration: none;
				position: relative;
				padding-left: 20px;
				display: inline-block;

				& + .arrow {
					display: block;
					float: right;
				}
			}

			.side-social {
				margin-left: 20px;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		.side-menu {
			.button {
				display: inline-block;
				margin: 30px 0 30px 20px;
			}
		}
	}

	@include max-tablet() {
		z-index: 999;

		&.fixed {
			.search {
				&.active {
					top: 80px;
				}
			}
		}

		[class^=span] {
			position: static;
		}

		.search {
			width: 100%;

			&.active {
				top: 90px;

				form {
					.submit-container {
						position: absolute;
						top: 14px;
						right: 30px;
						display: block;
						width: 21px;
						height: 21px;

						input[type="submit"] {
							color: $navy;
							background: transparent;
							display: block;
							width: 100%;
							height: 100%;
						}

						&:after {
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background-image: url('../images/icons/Search_Icon.png');
							pointer-events: none;
							background-size: cover;
						}
					}
				}
			}

			form {
				background-color: $navy;
				padding: 0 20px 10px;

				input[type="search"] {
					background-color: transparent;
					color: $white;
					@include placeholder-color(#8d989c);
					border-bottom: 2px solid $white;
				}
			}
		}
	}

	@include max-mobile() {

		.header-logo {
			img {
				max-width: 80px;
				margin-top: 13px;
			}
		}

		.search-ico {
			padding-right: 35px;
		}

		.search {

			&.active {
				top: 90px;

				form {
				}
			}
		}

		.side-menu {
			width: 100%;
			right: - 100%;

			.arrow {
				padding: 0 0 0 30px;
			}

			.close-menu {
				position: absolute;
				top: 15px;
				right: 20px;
				width: auto;
				padding: 15px;

				i {
					width: 20px;
					height: 20px;
				}
			}

			.side-navigation {
				max-width: 90%;
			}

			.sub-panel {
				right: -100%;
				width: 100%;
			}
		}
	}
}

ul.sub-nav,
ul.child-sub-nav {
	display: none;
}

.full-screen-menu {
	position: absolute;
	right: -75px;
	top: 30px;
	@include transition(right 0.3s ease);

	.menu-icon {
		&:hover {
			span {
				@include transform-origin(0 0);
				background-color: $white;

				&.top {
					@include rotate(22deg);
				}

				&.middle {
					opacity: 0;
				}

				&.bottom {
					@include rotate(-22deg);
				}
			}
		}
	}

	&.open {
		right: 50px;

		.menu-icon {
			span {
				@include transform-origin(0 0);
				background-color: $white;

				&.top {
					@include rotate(22deg);
				}

				&.middle {
					opacity: 0;
				}

				&.bottom {
					@include rotate(-22deg);
				}
			}
		}
	}
}

.service-bar {
	background-color: var(--orange);
	padding: 4px 0;
	width: 100%;
	z-index: 11;

	img {
		float: left;
		margin-right: 8px;
		padding-bottom: 2px;
	}

	a {
		color: $navy;

		body.high-contrast & {
			color: $white;
		}
	}

	p {
		float: left;
		margin: 0;
		color: white;
		padding-top: 2px;
		@include Din(regular);
		@include font-size(15);

		.type {
			@include Din(bold);
			margin-right: 2px;

			&:after {
				content: ':';
				display: inline-block;
				margin-right: 2px;
			}
		}
	}

	.message {
		float: left;
		@include calc(width, '100% - 100px');
	}

	& + .hero.standard-hero {

		&.hasBackground {
			.hero-content {
				padding-top: 110px;
			}
		}

		.hero-content {
			padding-top: 60px;
		}
	}

	@include max-desktop() {
		padding: 4px 20px;
		.row {
			[class^=span] {
				margin: 0;
			}
		}

		p {
			padding-top: 0
		}
	}

	@include small-desktop() {
		& + .hero.standard-hero {
			.hero-content {
				padding-top: 35px;
			}
		}
	}

	@include max-tablet() {
		padding: 10px 20px;
		top: 90px;

		& + .hero.standard-hero {
			.hero-content {
				padding-top: 85px;
			}
		}
	}

	@include max-mobile() {
		& + .hero.standard-hero {
			.hero-content {
				padding-top: 85px;
			}
		}

		.message {
			@include calc(width, '100% - 80px');
		}

		img {
			display: block;
			float: left;
		}

		p {
			display: block;
		}
	}
}
