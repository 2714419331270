.course-information {
	margin-top: -60px;
	clear: both;

	.row {
		@include flex();
		@include flex-wrap(wrap);
	}

	.span4 {
		margin-left: 0;
		width: 32%;
	}

	.span8 {
		margin-right: 0;
		float: right;
	}

	.course-glance {
		background-color: $white;
		padding: 45px;
		@include box-shadow(0, 1px, 3px, 1px, #d7d7d7);

		hr {
			margin: 0;
			background-color: var(--orange);
			width: 100%;
			height: 8px;
			border: 0;
		}

		h3 {
			@include Bree(bold-italic);
			@include font-size(30, 38);
			color: var(--orange);
			margin-top: 20px;
		}

		.course-item {

			span {
				@include Bree(bold);
				color: var(--orange);
				@include font-size(16);
				display: block;
				width: 100%;
				margin-bottom: 2px;
			}

			p {
				@include Din(regular);
				@include font-size(16);
				color: $navy;
				margin-bottom: 20px;
			}
		}

		.print-btn {
			display: block;
			@include Bree(bold-italic);
			@include font-size(18, 23);
			color: $navy;
			text-decoration: none;
			margin-top: 25px;

			&:before {
				content: '';
				background-image: url('../images/icons/printer-ico.png');
				width: 16px;
				height: 18px;
				display: inline-block;
				margin-right: 5px;
				position: relative;
				top: 3px;
				background-repeat: no-repeat;
			}
		}
	}

	.apply-cta {
		margin-top: 30px;
		background-color: var(--light-turq);
		text-align: center;
		padding: 40px 45px;
		width: 100%;
		max-width: 385px;
		@include transition(all .5s ease);
		position: absolute;
		top: 0;
		left: 0;

		&.fixed {
			position: relative;
			top: auto !important;
			left: auto !important;
		}

		h2 {
			width: 100%;
			@include Bree(bold-italic);
			@include font-size(30, 38);
			color: $white;
		}

		a.button {
			@include font-size(22, 19);
		}

		.apply-buttons {
			width: 100%;

			a.button {
				width: 100%;
				margin-bottom: 5px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		&.auto {
			top: auto !important;
		}

		@include small-desktop() {
			padding: 20px 25px;
		}
	}

	.apply-cta-bar {
		background-color: var(--light-turq);
		overflow: auto;
		padding: 30px 45px;
		display: table;
		width: 100%;

		h2 {
			@include Bree(bold-italic);
			@include font-size(30, 38);
			color: $white;
			display: table-cell;
			vertical-align: middle;
			margin: 0;

			i {
				@include font-size(23, 38);
				margin-left: 4px;
			}
		}

		a.button {
			float: right;
			margin-top: 3px;
		}

		.apply-buttons {
			float: right;
			margin-top: 3px;
			@include flex();
			@include flex-direction(column);
			@include flex-wrap(nowrap);

			a.button {
				float: none;
				width: 100%;
				margin-bottom: 4px;
				text-align: center;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		@include max-desktop() {
			padding: 30px 25px;
		}

		@media only screen and (max-width: 1024px) {
			h2 {
				@include font-size(24);
			}
		}

		@include small-desktop() {
			@include flex();
			@include flex-direction(column);
			@include flex-wrap(nowrap);

			h2 {
				width: 100%;
				text-align: center;
				margin-bottom: 10px;

				i {
					display: none;
				}
			}
		}

		@include max-mobile() {

			h2 {
				margin-bottom: 5px;
			}

			.apply-buttons,
			h2 {
				width: 100%;
				float: none;
				clear: both;
				display: block;
			}
		}
	}

	.course-content {
		margin-bottom: 35px;
	}

	.course-details {
		background-color: $white;
		padding: 30px 50px;

		hr {
			margin: 40px 0;
			background-color: var(--green);
			width: 100%;
			height: 8px;
			border: 0;
		}

		h3 {
			@include Bree(bold-italic);
			color: $navy;
			@include font-size(30);
			margin-bottom: 25px;
		}

		p {
			@include Din(light);
			color: $navy;
			@include font-size(18, 28);
		}

		strong {
			@include Din(bold);
			@include font-size(18);
			color: $navy;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding-bottom: 10px;
				@include Din(light);
				color: $navy;
        @include font-size(18, 28);
        padding-left: 9px;
        position: relative;
				&:before {
					content: '';
					width: 3px;
					height: 3px;
					background-color: $navy;
					@include border-radius(50%);
					float: left;
					margin: 12px 6px 16px 0;
          display: block;
          position: absolute;
          left: 0;
				}
			}
		}

		.print-btn {
			display: block;
			@include Bree(bold-italic);
			@include font-size(18, 23);
			color: $navy;
			text-decoration: none;
			margin-top: 25px;

			&:before {
				content: '';
				background-image: url('../images/icons/printer-ico.png');
				width: 16px;
				height: 18px;
				display: inline-block;
				margin-right: 5px;
				position: relative;
				top: 3px;
				background-repeat: no-repeat;
			}
		}
	}

	@include max-desktop() {
		margin-top: -30px;
		margin-bottom: 30px;
	}

	@include small-desktop() {

		.course-glance {
			padding: 30px;
		}

		.apply-cta-bar,
		.course-details {
			padding: 25px;
		}

		.apply-cta-bar {
			text-align: center;
			h2 {
				margin-right: 20px;
				float: none;
			}

			a.button {
				float: none;
			}
		}
	}

	@include max-tablet() {
		[class^=span] {
			width: 100% !important;
			margin: 0;
		}

		.apply-cta {
			display: none;
		}

		.course-content {
			margin: 35px 0;
		}
	}

	@include max-mobile() {
		[class^=span] {
			width: 100% !important;
			margin: 0;
		}

		.apply-cta {
			display: none;
		}

		.course-content {
			margin: 35px 0;
		}
	}
}
