.course-search {
	margin-bottom: 30px;
	clear: both;
	padding: 0 15px;

	.row {
		margin-top: -100px;
		@include box-shadow(0, 0, 2px, 1px, #d7d7d7);
	}

	.course-overview {
		background-color: $white;
		padding:  30px 25px 25px;
		display: table;
		width: 100%;

		h2 {
			@include Bree(bold-italic);
			color: var(--turquoise);
			@include font-size(24);
			margin-bottom: 25px;
		}

		img {
			display: block;
			margin: 0 auto;

			&.b-loaded {
				width: 302px;
				min-width: 302px;
			}
		}
	}

	.course-search-box {
		background-color: $light-green;
		display: table;
		width: 100%;
		padding: 20px 30px 0;

		[class^=span] {
			&.span8 {
				margin: 0;
			}
		}

		h2 {
			@include Bree(bold-italic);
			color: $navy;
			@include font-size(30, 41);
			width: 210px;
			display: block;
			float: left;
		}

		form {
			display: inline-block;
			@include calc(width, '100% - 272px');
		}

		a.reset {
			text-decoration: none;
			color: $navy;
			@include font-size(15);
			@include Din(demi);
			text-align: right;
			display: block;
			float: right;
			margin-top: 15px;
			width: 52px;

			i {
				display: inline-block;
				background-image: url('../images/icons/reset-ico.png');
				width: 13px;
				height: 13px;
				background-size: cover;
				position: relative;
				top: 1px;
				margin-right: 3px;
			}

			&:hover,
			&:focus {
				text-decoration: underline;

				i {
					@include animation(resetRotate 1s);
				}
			}
		}

		form {

			input[type="search"] {
				-webkit-box-sizing: border-box;
     			-moz-box-sizing: border-box;
          		box-sizing: border-box;
				width: 100%;
				border: 0;
				height: 45px;
				@include border-radius(5px 0 0 5px);
			}

			fieldset {
				float: left;
				@include calc(width, '100% - 130px');
			}

			.search-input {
				width: 128px;
				float: left;
				background-color: $navy;
				color: $white;
				// text-align: center;
				position: relative;
				height: 45px;
				padding: 0 20px;
				@include border-radius(0 5px 5px 0);
				@include transition(all .3s ease);
				&:hover {
					background-color: #0a5365;
				}

				i {
					display: block;
					color: $white;
					padding-top: 15px;
					margin-left: 5px;
				}

				input[type="submit"] {
					display: inline-block;
					background-color: transparent;
					@include Bree(bold-italic);
					@include font-size(18);
					color: $white;
					position: absolute;
					width: 100%;
					height: 100%;
					top: -1px;
					left: 0;
					text-indent: 17px;
					z-index: 1;
				}
			}
		}
	}

	@include max-desktop() {
		.row {
			margin-top: -50px;
		}
	}

	@include max-mobile-nav() {
		.row {
			[class^=span] {
				&:nth-of-type(1) {
					min-width: 230px;
					width: 60%;
				}

				&:nth-of-type(3) {
					margin: 0;
					width: 7.1%;
				}
			}
		}
	}

	@media only screen and (max-width: 920px) {
		.row {
			.course-overview {
				[class^=span] {
					&:nth-of-type(1) {
						width: 52%;
					}

					&:nth-of-type(2) {
						width: 43%;
					}
				}
			}
		}
	}

	@include max-tablet() {
		.course-search-box {
			padding: 20px 30px;
			h2 {
				width: 100%;
				margin-bottom: 10px;
			}

			form {
				@include calc(width, '100% - 62px');
			}
		}

		.row {
			[class^=span] {

				&.span3 {
					width: 100%;
					margin: 0;
				}

				&.span8 {
					padding-left: 35px;
					width: 80%;
				}

				&.span1,
				&:nth-of-type(3) {
					margin: 0 1.25%;
					width: (percentage((2*$column+2*$margin)/$max-width));
				}
			}

			.course-overview {

				img {
					display: block;
					margin: 0 auto;

					&.b-loaded {
						width: auto;
						min-width: auto;
					}
				}

				[class^=span] {
					width: 100%;
					margin: 0;
					&:nth-of-type(1),
					&.span8,
					&:nth-of-type(2) {
						width: 100%;
						margin: 0;
						padding-left: 0;
					}
				}
			}
		}


	}

	@include max-mobile() {
		.course-overview {
			padding: 25px;
		}

		.course-search-box {
			padding: 10px 15px;

			h2 {
				// padding-left: 15px;
			}

			a.reset {
				text-align: center;
				@include font-size(18);
				margin-top: 4px;
				width: 50px;
			}

			form {

				fieldset {
					@include calc(width, '100% - 60px');
				}

				input[type="search"] {
					margin-bottom: 0;

					&::-webkit-input-placeholder {
					  @include font-size(14, 25);
					}

					&::-moz-placeholder {
					  @include font-size(14, 25);
					}

					&:-ms-input-placeholder {
					  @include font-size(14, 25);
					}

					&:-moz-placeholder {
					  @include font-size(14, 25);
					}
				}

				.search-input {
					text-align: center;
					width: 60px;
					i {
						display: inline-block;
					}

					input[type="submit"] {
						text-indent: -9999px;
						@include font-size(0, 0);
					}
				}
			}
		}
	}
}

.course {
	padding: 0 15px;

	@include max-desktop() {
		padding: 0;
	}
}

.course-listing-filters {

	h3 {
		@include Bree(bold-italic);
		color: $navy;
		@include font-size(24);
		margin-top: 5px;
		margin-bottom: 0;
	}

	.filters {
		width: 50%;
		float: right;

		form {
			text-align: right;
			fieldset {
				margin-left: 1.25%;
				width: 30.8%;
				display: inline-block;

				.select-wrapper {
					.select-arrow {
						&:before {
							left: -8px;
						    position: relative;
						}
					}
				}
			}
		}
	}

	@include max-mobile-nav() {

		h3 {
			margin-bottom: 15px;
		}

		[class^=span] {
			&.expand {
				width: 100%;
				margin: 0;
				float: left;
				text-align: left;

				form {
					fieldset {
						display: block;
						width: 32.5%;
						float: left;

						&:first-of-type {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	@include max-mobile() {
		[class^=span] {
			&.expand {
				form {
					fieldset {
						margin: 0;
						width: 100%;

						.select-wrapper {
							.select-arrow {
								bottom: 16px;

								&:before {
									@include font-size(25, 48);
								}
							}
						}
					}
				}
			}
		}
	}
}

.course-listing {
	margin-top: 30px;

	.listing-item {
		@include flex();
		margin-bottom: 20px;
		@include box-shadow(0, 1px, 8px, 1px, #d7d7d7);

		.listing-image {
			width: 25%;
			max-width: 285px;
			float: left;
			overflow: hidden;
			min-height: 230px;
			position: relative;

			img {
				display: block;
				max-width: none;
				position: absolute;
				top: 50%;
		    left: 50%;
		    min-height: 100%;
		    min-width: 100%;
		    width: auto;
		    height: 100%;
		    @include transform(translate(-50%, -50%));
		    @include transition(transform .25s ease-out);

		    &:hover,
		    &:focus {
		    	@include transform(translate(-50%, -50%) scale(1.05));
		    }
			}

			&:hover,
	    &:focus {

	    	img {
	    		@include transform(translate(-50%, -50%) scale(1.05));
	    	}
	    }
		}

		.listing-content {
			width: 77%;
			float: left;
			background-color: $white;
			padding: 20px 30px;
			overflow: hidden;
			position: relative;
			// @include flex();

			h4 {
				@include Bree(bold-italic);
				@include font-size(24);
				color: var(--turquoise);
			}

			.listing-summary {
				width: 65%;
				float: left;

				a {
					text-decoration: none;
				}

				p {
					@include font-size(16);
					color: $navy;
					padding-right: 15px;
				}

			}

			.button-row {
				width: 65%;
				float: left;

				a.button {
					&:first-of-type {
						margin-right: 15px;
						margin-bottom: 15px;
					}
				}
			}

			.listing-details {
				width: 34.5%;
				float: right;
				padding-left: 20px;
				border-left: 1px solid #d5d5d6;


				ul {
					list-style-type: none;

					li {
						@include Din(regular);
						@include font-size(16);
						color: $navy;

						// &:before {
						// 	content: '';
						// 	width: 5px;
						// 	height: 5px;
						// 	background-color: $navy;
						// 	display: inline-block;
						// 	@include border-radius(50%);
						// 	position: relative;
						// 	top: -2px;
						// 	margin-right: 6px;
						// }

						span {
							@include Din(bold);
						}
					}
				}
			}
		}
	}

	@include small-desktop() {
		.listing-item {
			.listing-image {
				width: 33.333%;
			}
			.listing-content {
				padding: 20px 15px;
				@include flex-wrap(wrap);
				width: 70%;

				.listing-image {
					max-height: 400px;
				}

				.listing-summary,
				.listing-details {
					width: 100%;
				}

				.listing-details {
					padding-left: 0;
					padding-top: 16px;
					margin-top: 20px;
					border-left: 0;
					border-top: 1px solid #d5d5d6;
				}
			}
		}
	}

	@include max-tablet() {
		.listing-item {

			.listing-image {
				img {
					max-height: 400px;
				}
			}

			.listing-content {
				padding: 20px 15px 0 15px;
				display: block;

				.button-row {
					width: 100%;
					margin: 20px 0;
				}

				.listing-details {
					ul {
						margin: 0;
					}
				}
			}
		}
	}

	@include max-mobile() {
		.listing-item {
			display: inline-block;
			width: 100%;

			&:last-of-type {
				margin-bottom: 0;
			}

			.listing-image {
				width: 100%;
				max-width: 100%;
				max-height: 190px;

				img {
					max-height: none;
				}
			}

			.listing-content {
				width: 100%;
				padding: 20px 15px;
				.button-row {
					margin-bottom: 0;
					a.button {
						width: 100%;
						text-align: center;

						&:first-of-type {
							margin-right: 0;
							margin-bottom: 15px;
						}
					}
				}
			}

		}
	}
}

.pagination {
	text-align: center;
	margin: 65px 0 55px;
	clear: both;
	width: 100%;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: inline-block;

		li {
			display: inline-block;
			height: 30px;
			@include Din(demi);
			@include font-size(18, 30);
			color: $navy;
			margin-right: 7px;
			width: 24px;
			@include transition(all 0.3s ease);

			&.active,
			&:hover {
				background-color: var(--turquoise);
				color: $white;
				@include border-radius(4px);
			}

			&.prev,
			&.next {
				width: 70px;
				text-align: center;
				background-color: $white;
				@include Bree(bold-italic);
				color: var(--turquoise);
				@include font-size(16, 28);
				@include border-radius(4px);
				@include box-shadow(0, 1px, 8px, 1px, #d7d7d7);
				@include transition(all 0.3s ease);

				&:hover {
					background-color: var(--turquoise);
					color: $white;
				}
			}

			&.prev {
				margin-right: 15px;
			}

			&.next {
				margin: 0;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}

	@include max-mobile() {
		padding: 0;
		margin: 7px 0 35px;

		ul {
			li {
				&.prev {
					margin-right: 10px;
				}

				&.next {
					margin-left: 10px;
				}
			}
		}
	}
}

@keyframes resetRotate {
	0% { @include transform(rotate(0)) }
	100% { @include transform(rotate(360deg)) }
}
