.cta-bar {
	background-color: var(--light-turq);
	min-height: 120px;
	padding-top: 39px;

	h2 {
		@include Bree(bold-italic);
		color: $navy;
		@include font-size(35, 37);

		body.high-contrast & {
			color: $white;
		}
	}

	p {
		@include SourceSans(semi-bold);
		color: white;
	}

	a.button {
		.close {
			opacity: 0;
			visibility: hidden;
			display: none;
		}

		&.active {
			.close {
				opacity: 1;
				visibility: visible;
				display: block;
			}

			.text {
				opacity: 0;
				visibility: hidden;
				display: none;
			}
		}

		&.button-yellow {
			body.high-contrast & {
				background-color: $black;
			}
		}
	}

	.checkbox-wrapper {
		label {
			color: $white;
		}
	}

	.cta-form {
		display: none;
	}

	@include max-desktop() {
		padding-left: 0;
		padding-right: 0;

		.row {
			[class^=span] {
				&.offset1 {
					width: (percentage((9*$column+16*$margin)/$max-width));
					margin: 0 (percentage($margin/$max-width));
				}
			}
		}
	}

	@include small-desktop() {
		text-align: center;
		padding: 25px 20px;

		.row {
			[class^=span] {
				width: 100%;
				margin: 0;
				&.offset1 {
					width: 100%;
					margin: 0;
				}
			}
		}

		a.button {
			float: none;
		}

		.checkbox-wrapper {
			label {
				text-align: left;
			}
		}
	}
}

.event-pod {
	background-color: $white;
	background-image: url('../images/event-bg.png');
	background-position: bottom right;
	background-size: auto;
	background-repeat: no-repeat;
	text-align: center;
	padding: 80px 0;

	h2 {
		color: var(--orange);
		@include Bree(bold-italic);
		@include font-size(36, 38);
		width: 100%;
		text-align: center;
	}

	p {
		display: block;
		max-width: 800px;
		margin: 0 auto 16px;
	}

	a.link {
		color: var(--orange);

		&:after {
			border-right: 1px solid var(--orange);
			border-bottom: 1px solid var(--orange);
		}
	}

	.event-list {
		margin-top: 40px;

		@include min-desktop(){
			@include flex();
			@include flex-align(center);
			justify-content: center;
		}

		[class^=span] {

			.shape {
				position: absolute;
				width: 100%;
				height: 100%;
			}

			&:nth-of-type(1) {
				.event-inner {
					background: url('../images/Event_Listing_Shape_1.svg');
					background-size: cover;
					background-repeat: no-repeat;
					position: relative;

					@include max-desktop() {
						background: var(--green);
					}

					&:before {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 20px;
						top: 1px;
						@include translatey(-100%);
						background: var(--green);
						left: 0;
						clip-path: polygon(0 0, 0 100%, 100% 100%);
					}

					&:after {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 10px;
						bottom: 1px;
						@include translatey(100%);
						background: var(--green);
						left: 0;
						clip-path: polygon(0 0, 0 100%, 100% 0);
					}

					@supports ( clip-path: polygon(0 100%, 100% 100%, 100% 0)){
						background: var(--green);

						&:after,
						&:before {
							display: block;
						}
					}

				}
			}

			&:nth-of-type(2) {
				.event-inner {
					background: url('../images/Event_Listing_Shape_2.svg');
					background-size: cover;
					background-repeat: no-repeat;
					position: relative;

					@include max-desktop() {
						background: var(--turquoise);
					}

					&:before {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 20px;
						top: 1px;
						@include translatey(-100%);
						background: var(--turquoise);
						left: 0;
						clip-path: polygon(0 100%, 100% 100%, 100% 0);
					}

					&:after {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 10px;
						bottom: 1px;
						@include translatey(100%);
						background: var(--turquoise);
						left: 0;
						clip-path: polygon(0 0, 100% 100%, 100% 0);
					}

					@supports ( clip-path: polygon(0 100%, 100% 100%, 100% 0)){
						background: var(--turquoise);

						&:after,
						&:before {
							display: block;
						}
					}
				}
			}

			&:nth-of-type(3) {
				.event-inner {
					background: url('../images/Event_Listing_Shape_3.svg');
					background-size: cover;
					background-repeat: no-repeat;
					position: relative;

					@include max-desktop() {
						background: var(--light-turq);
					}

					&:before {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 20px;
						top: 1px;
						@include translatey(-100%);
						background: var(--light-turq);
						left: 0;
						clip-path: polygon(0 0, 0 100%, 100% 100%);
					}

					&:after {
						content: '';
						position: absolute;
						display: none;
						width: 100%;
						height: 10px;
						bottom: 1px;
						@include translatey(100%);
						background: var(--light-turq);
						left: 0;
						clip-path: polygon(0 0, 0 100%, 100% 0);
					}

					@supports ( clip-path: polygon(0 100%, 100% 100%, 100% 0)){
						background: var(--light-turq);

						&:after,
						&:before {
							display: block;
						}
					}
				}
			}
		}

		.event-inner {
			padding: 24px 15px;
			display: inline-block;
			width: 100%;
			position: relative;
			z-index: 5;

			@supports ( clip-path: polygon(0 0, 0 100%, 100% 100%) ){
				max-width: 340px;
				width: 100%;
			}

			.event-date {
				float: left;
				width: 30%;
				max-width: 80px;

				span {
					@include Bree(bold-italic);
					color: $white;
					@include font-size(21, 21);
					display: block;
					width: 100%;

					&:nth-of-type(2) {
						@include font-size(55, 47);
						padding-bottom: 3px;
					}
				}
			}

			.event-content {
				float: left;
				width: 70%;
				text-align: left;
				padding-left: 12px;

				h4 {
					@include Bree(bold-italic);
					color: white;
					@include font-size(19);
					margin: 0 0 5px 0;

					a {
						color: inherit;
						text-decoration: none;
					}
				}

				p {
					margin: 0 0 5px 0;
					color: white;
					text-align: left;
					&.event-location {
						@include SourceSans(bold);
						@include font-size(16);

						&:before {
							content: '';
							display: inline-block;
							background-image: url('../images/icons/map-marker.png');
							width: 15px;
							height: 19px;
							position: relative;
							top: 5px;
							margin-right: 5px;
						}
					}

					&.event-time {
						@include Din(regular);
						@include font-size(16);
					}
				}
			}
		}
	}

	@include small-desktop() {

		p {
			padding: 0 20px;
		}

		.event-list {
			[class^=span] {
			    width: 97.5%;

				.event-inner {
					width: 100%;
					max-width: 340px;
					.event-content {
						p {
							padding: 0;
						}
					}
				}
			}
		}
	}

	@include max-mobile() {
		.event-list {
			.event-inner {
				.event-content {
					padding-left: 8px;

					a.button {
						padding: 11px 14px 13px 13px;
					}
				}
			}
		}
	}
}

.promo-pod {
	background-color: #f4f4f4;

	.row {
		min-height: 360px;
	}

	[class^=span] {
		position: absolute;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));

		h2 {
			@include Bree(bold-italic);
			@include font-size(36);
			color: var(--turquoise);
		}

		.promo-quote {
			position: relative;
			display: inline-block;
			@include Din(bold);
			@include font-size(19);
			color: var(--light-turq);
			@include calc(width, '100% - 23px');
			margin: 0;
			padding-left: 25px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 7px;
				height: 100%;
				background-color: var(--turquoise);
			}
		}

		ul {
			padding-left: 17px;
			margin-top: 25px;

			li {
				@include Din(regular);
				@include font-size(17, 25);
				color: $navy;
				padding-bottom: 5px;
			}
		}
	}

	[class^=offset] {
		overflow: hidden;

		img {
			display: block;
			max-width: 1150px;
			width: auto;
		}
	}

	@include small-desktop() {
		padding: 0;
		[class^=span],
		[class^=offset] {
			width: 100%;
			position: static;
			margin: 0;
			@include transform(none);

			img {
				display: block;
				max-width: 100%;
			}

			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		[class^=span] {
			padding: 30px 20px 0;
			max-width: 750px;
			margin: 0 auto;
			float: none;
		}
	}
}

.career-cta {
	margin-bottom: 40px;

	.row {
		background-color: var(--orange);

		[class^=span] {
			&:first-of-type {
				padding: 55px 25px 0;
			}

			&:last-of-type {
				float: right;
				overflow: hidden;
			}
		}

		h2 {
			@include Bree(bold);
			@include font-size(50, 55);
			color: $white;

			span {
				color: var(--yellow);

				body.high-contrast & {
					color: $yellow;
				}
			}
		}

		p {
			@include Bree(bold-italic);
			color: var(--yellow);
			@include font-size(18, 24);

			body.high-contrast & {
				color: $yellow;
			}
		}

		img {
			float: right;
		}
	}

	@include max-desktop() {
		.row {
			[class^=span] {
				&:first-of-type,
				&:last-of-type {
					padding: 25px 0 0 25px;

					&.expand {
						padding: 0;
					}
				}
			}

			img {
				max-width: none;
				float: none;
				min-width: 550px;
				display: block;
			}
		}
	}

	@include small-desktop() {
		.row {

			h2 {
				margin-bottom: 10px;
				@include font-size(40);
			}

			p {
				margin-bottom: 8px;
			}

			img {
				max-width: 100%;
				display: block;
				width: 100%;
			}
		}
	}

	@include max-tablet() {
		.row {
			[class^=span] {
				width: 100%;
				margin: 0;

				&:first-of-type {
					padding: 25px 25px 30px;
				}
			}
		}
	}

	@include max-mobile() {
		.row {
			h2 {
				@include font-size(40);
			}
		}
	}
}

.course-category-links {
	background: url('../images/course-category-links-background-right.png');
	background-repeat: no-repeat;
	background-position: top right;
	background-color: $light-turq;
	padding: 60px 0 85px;
	margin-top: 80px;

	h3 {
		@include Bree(bold-italic);
		@include font-size(30);
		color: $white;
		margin-bottom: 38px;
	}

	&.profile-listing-block {

		.profile-listing {
			margin-top: 32px;

			.view-all-cta {
				margin: 1rem auto auto;
				flex: 1 1 100%;
				text-align: center;

				a {
					@include font-size(15);
				}
			}
		}

		.title {
			position: relative;
			margin: 32px 0;
			display: table;
			width: 30%;
			min-width: 275px;

			.shape {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
				@include rotate(-3deg);
				opacity: 1;
				background-color: #FFFFFF;

				&:before {
					content: '';
					position: absolute;
					width: 750%;
					height: 100%;
					left: -749%;
					background-color: inherit;
				}

				&:after {
					content: '';
					width: 150px;
					height: 100%;
					position: absolute;
					top: 0;
					right: -40px;
					background-color: inherit;
					backface-visibility: hidden;
					@include transform(skew(-20deg));
				}
			}

			.h3-title {
				position: relative;
				z-index: 5;

				@include transform(rotate(-3deg));

				h3 {
					@include font-size(36,54);
					color: $light-turq;
					margin-bottom: 1rem;
					padding: 10px 0;
				}
			}
		}
	}

	&-listing {
		@include flex();
		@include flex-wrap(wrap);

		&.profile-listing {
			img {
				max-height: 200px;
				object-fit: cover;
			}
		}
	}

	.row {
		@include flex();
		@include flex-wrap(wrap);
		@include flex-direction(row);
		// IE9 Fix
		html.no-flexbox & {
			display: block;

			[class^=span] {
				display: block;
				float: left;
				padding: 0;
			}
		}
	}

	[class^=span] {
		span {
			@include Bree(bold-italic);
			@include font-size(19);
			color: var(--turquoise);
			margin-bottom: 10px;
			display: block;
		}

		a {
			color: var(--turquoise);
			text-decoration: none;
			display: inline-block;
			width: 100%;
			position: relative;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:after {
				border-color: var(--turquoise);
				position: absolute;
				margin-top: 3px;
			}
		}

		&:nth-of-type(1) {
			margin-left: 0;
		}

		&:nth-child(5n + 2),
		&:nth-of-type(2) {
			span {
				color: var(--orange);
			}

			a {
				color: var(--orange);

				&:after {
					border-color: var(--orange);
				}
			}
		}

		&:nth-child(5n + 3),
		&:nth-of-type(3) {
			span {
				color: var(--yellow);
			}

			a {
				color: var(--yellow);

				&:after {
					border-color: var(--yellow);
				}
			}
		}

		&:nth-child(5n + 4),
		&:nth-of-type(4) {

			span {
				color: var(--green);
			}

			a {
				color: var(--green);

				&:after {
					border-color: var(--green);
				}
			}
		}

		&:nth-child(5n + 5),
		&:nth-of-type(5) {
			span {
				color: var(--brown);
			}

			a {
				color: var(--brown);

				&:after {
					border-color: var(--brown);
				}
			}
		}

		&.span4 {
			width: 31.666%;
			@include flex();
			@include flex-direction(column);
			margin-bottom: 2.5%;
			background-color: $white;
			@include box-shadow(0, 1px, 8px, 1px, rgba(0, 0, 0, 0.15));

			&:nth-child(3n + 1) {
				margin-left: 0;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	.content {
		flex: 1 0 auto;
		@include flex();
		@include flex-direction(column);
		padding: 20px 25px 25px;
	}

	p {
		flex: 1 0 auto;
		@include Din(regular);
		@include font-size(16);
		color: $navy;
	}

	@include max-desktop() {
		padding-left: 20px;
		padding-right: 20px;
	}

	@include max-tablet() {
		[class^=span] {
			&.span4 {
				width: 47.5%;
				margin-bottom: 5%;

				&:nth-child(4n) {
					margin-left: 0;
				}

				&:nth-child(odd) {
					margin-left: 0;
					margin-right: 2.5%;
				}

				&:nth-child(even) {
					margin-left: 2.5%;
					margin-right: 0;
				}
			}
		}
	}

	@include max-mobile() {
		[class^=span] {
			&.span4 {
				width: 100%;
				margin: 0 0 20px;

				&:nth-child(4n),
				&:nth-child(5n) {
					margin: 0 0 20px;
				}

				&:nth-child(odd) {
					margin-left: 0;
					margin-right: 0;
				}

				&:nth-child(even) {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
}

.related-courses {
	margin: 50px 0;

	h2 {
		@include Bree(bold-italic);
		@include font-size(36);
		color: var(--turquoise);
		float: left;
	}

	a.all-courses {
		float: right;
		margin-top: 10px;
	}

	@include max-desktop() {
		.course-listing {
			padding: 0;
		}
	}

	@include max-mobile() {

		h2 {
			width: 100%;
		}

		a.all-courses {
			margin-top: 0;
		}

		h2,
		a.all-courses {
			float: left;
		}
	}
}

.latest-news {
	background: url('../images/latest-news-background-left.png'), url('../images/latest-news-background-right.png');
	background-repeat: no-repeat, no-repeat;
	background-position: bottom left, bottom right;
	padding: 80px 0 100px;

	h2 {
		@include Bree(bold-italic);
		@include font-size(36);
		color: var(--turquoise);
		float: left;
	}

	a.link {
		float: right;
		color: var(--turquoise);
		margin-top: 14px;

		&:after {
			border-color: var(--turquoise);
		}
	}

	.row {
		@include flex();
		@include flex-wrap(wrap);
	}

	.news-item {
		overflow: hidden;

		@include min-desktop() {
			@include flex();
			@include flex-direction(column);
			align-items: stretch;
		}

		.news-image {
			position: relative;
			// height: 100%;
			// overflow: hidden;

			.news-image-inner {
				position: relative;
				overflow: hidden;
			}

			img {
				display: block;
				width: 100%;
			}

			.shape {
				position: absolute;
				bottom: -10px;
				left: -10%;
				right: 0;
				width: 120%;
				height: 50px;
				background-color: rgba(6, 172, 139, 0.9);
				@include transform(rotate(4deg));

				body.high-contrast & {
					background-color: $light-turq-high-contrast;
				}
			}

			.image-overlay {
				position: absolute;
				bottom: 5px;
				left: 0;
				padding: 0 20px;
				width: 100%;
				color: $white;

				span {
					display: inline-block;
					@include Din(demi);
					@include font-size(15);

					&.category {
						&:after {
							content: '';
							display: inline-block;
							width: 3px;
							height: 3px;
							@include border-radius(50%);
							background-color: $white;
							margin: 0 4px 0 8px;
							position: relative;
							top: -3px;
						}
					}
				}
			}

			&:focus-within {
				outline: 2px solid #000;
				outline-offset: 2px;
				border: 2px solid #fff !important;
			}
		}

		.news-content {
			background-color: $white;
			padding: 25px 20px;
			height: 100%;

			@include min-desktop() {
				@include flex();
				@include flex-direction(column);
			}

			a {
				text-decoration: none;
			}

			h4,
			h4 a {
				@include Bree(bold-italic);
				@include font-size(17);
				color: var(--turquoise);
			}

			p {
				@include font-size(16);
				color: $navy;
				@include Din(regular);
			}

			a.link {
				float: none;
				margin-top: auto;
			}
		}
	}

	@include max-desktop() {
		padding: 80px 20px 100px;
	}

	@include small-desktop() {
		.news-item {

			&.expand {
				width: 50%;
				margin-bottom: 30px;

				.news-image {
					.shape {
						bottom: -20px;
						height: 65px;
					}
				}
			}
		}
	}

	@include max-mobile() {
		.news-item {

			&.expand {
				width: 100%;
			}
		}

		h2 {
			width: 100%;
			margin-bottom: 5px;
		}

		a.link {
			margin-top: 0;
		}

		a.all-articles {
			margin-bottom: 35px;
		}

		h2,
		a.link {
			float: left;
		}

		.news-item {
			.news-image {
				.image-overlay {
					span {
						@include font-size(17);
					}
				}
			}

			.news-content {
				h4 {
					@include font-size(20);
				}

				p,
				a.link {
					@include font-size(17);
				}
			}
		}
	}
}

.faq-pod {
	margin: 35px 0;
	background-color: $white;
	padding: 60px 75px;
	@include box-shadow(0, 1px, 4px, 0, #d7d7d7);

	h3 {
		@include Bree(bold-italic);
		color: var(--light-turq);
		@include font-size(28);
		padding: 0;
		margin-bottom: 20px;
	}

	.faq-item {
		// padding: 0 20px;
		margin-bottom: 15px;


		.faq-title {
			background-color: var(--green);
			color: $white;
			@include Din(demi);
			@include font-size(20);
			padding: 12px 40px 12px 20px;
			position: relative;
			cursor: pointer;
			@include transition(all .3s ease);

			i.arrow {
				width: 12px;
				height: 12px;
				position: absolute;
				right: 20px;
				top: 50%;
				@include transform(translateY(-50%));

				&:after {
					content: '';
					border-bottom: 7px solid transparent;
					border-top: 7px solid transparent;
					border-left: 12px solid $white;
					display: block;
					width: 0;
					height: 0;
					@include transition(all .3s ease);
				}
			}

			&.active {
				background-color: $white;
				border-bottom: 2px solid $grey;
				color: $grey;
				i.arrow {
					@include transform(translateY(-50%));
					&:after {
						@include transform(rotate(90deg));
						border-left: 12px solid $grey;
					}
				}
			}
		}

		.faq-content {
			padding: 20px;
			display: none;
			@include Din(light);
			@include font-size(18, 28);
			color: #2c4144;
		}
	}

	@include max-desktop() {
		margin: 0 15px;
	}

	@include max-tablet() {
		padding: 60px 30px;
	}

	@include max-mobile() {
		padding: 30px 15px;
	}
}

.news-pod {
	padding: 75px 0;

	h2 {
		@include Bree(bold-italic);
		color: $purple;
		margin-bottom: 45px;
		@include font-size(36);
		text-align: center;
		width: 100%;
	}

	.news-grid {
		display: inline-block;
		width: 100%;

		.news-item {
			float: left;
			position: relative;
			display: flex;
			flex-direction: column-reverse;
			align-items: flex-end;
			height: 290px;
			overflow: hidden;

			@media (min-width: 768px) {
				height: 450px;
			}

			&:after {
				content: '';
				width: 100%;
				height: 25px;
			}

			&:hover,
			&:focus-within {

				img {
					@include transform(scale(1.05));
					@include transition(transform .2s ease);
				}
			}

			a {
				color: inherit;
				text-decoration: none;
			}

			&.news-small {
				width: 25%;
			}

			&.news-large {
				width: 50%;
			}

			&.video {
				&:before {
					content: '\f04b';
					font-family: 'FontAwesome';
					position: absolute;
					top: 50%;
					@include transform(translateY(-80%));
					width: 60px;
					height: 60px;
					border: 5px solid $white;
					@include border-radius(50%);
					left: 0;
					right: 0;
					margin: 0 auto;
					text-align: center;
					color: $white;
					@include font-size(30, 60);
					line-height: 60px;
					text-indent: 6px;
					cursor: pointer;
				}

				a.link {
					&:after {
						content: '';
						display: block;
						float: right;
						margin-left: 4px;
						border: none;
						@include transform(none);
						width: 17px;
						height: 17px;
						margin-top: 3px;
						background-image: url('../images/icons/play-ico-small.png');
					}

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}

			&.purple {
				&:after {
					background-color: $purple;
					@include transform(rotate(3deg) translateY(10px));
				}

				.content-overlay {
					background-color: $purple;
				}
			}

			&.yellow {
				&:after {
					background-color: var(--yellow);
					@include transform(rotate(-2deg) translateY(10px));

					body.high-contrast & {
						background-color: #747578;
					}
				}

				.content-overlay {
					background-color: var(--yellow);

					body.high-contrast & {
						background-color: #747578;
					}
				}
			}

			&.navy {
				&:after {
					background-color: $navy;
					@include transform(rotate(-2deg) translateY(10px));
				}

				.content-overlay {
					background-color: $navy;
				}
			}

			&.green {
				&:after {
					background-color: var(--turquoise);
					@include transform(rotate(-2deg) translateY(10px));

					body.high-contrast & {
						background-color: #21702B;
					}
				}

				.content-overlay {
					background-color: var(--turquoise);

					body.high-contrast & {
						background-color: #21702B;
					}
				}
			}

			&.brown {
				&:after {
					background-color: var(--brown);
					@include transform(rotate(3deg) translateY(10px));

					body.high-contrast & {
						background-color: #8e630a;
					}
				}

				.content-overlay {
					background-color: var(--brown);

					body.high-contrast & {
						background-color: #8e630a;
					}
				}
			}

			&.orange {
				&:after {
					background-color: var(--orange);
					@include transform(rotate(-3deg) translateY(10px));

					body.high-contrast & {
						background-color: #5d2f26;
					}
				}

				.content-overlay {
					background-color: var(--orange);

					body.high-contrast & {
						background-color: #5d2f26;
					}
				}
			}

			img {
				display: block;
				width: 100%;
				min-width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				@include transition(transform .2s ease);
			}

			.content-overlay {
				z-index: 1;
				padding: 15px 20px;
			}

			small {
				@include Din(regular);
				@include font-size(14);
				color: $white;
				margin-bottom: 5px;
				display: block;
			}

			.title {
				@include Bree(bold-italic);
				color: $white;
				@include font-size(18);
				margin-bottom: 5px;
			}

			.date {
				color: $white;
				@include Bree(bold-italic);
				@include font-size(21);

				span {
					@include font-size(42);
				}
			}

			p {
				@include Din(regular);
				color: $white;
				margin-bottom: 5px;
				@include font-size(16);

				&.location {
					margin: 11px 0 1px;

					&:before {
						content: '';
						background: url('../images/icons/map-marker.png');
						width: 15px;
						height: 21px;
						display: block;
						float: left;
						background-repeat: no-repeat;
						margin-right: 9px;
						background-position: 0 3px;
						background-size: contain;
					}
				}
			}

			time {
				@include Din(regular);
				color: $white;
				margin-bottom: 5px;
				@include font-size(16);
				margin: 3px 0 8px;
				display: block;
				width: 100%;

				&:before {
					content: '\f017';
					font-family: 'FontAwesome';
					width: 16px;
					height: 16px;
					font-size: 16px;
					font-size: 1rem;
					line-height: 22px;
					line-height: 1.375rem;
					text-align: center;
					display: block;
					float: left;
					margin-right: 5px;
					margin-top: 0;
				}
			}

			.link {
				color: $white;

				&:hover,
				&:focus {
					text-decoration: underline;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 1;
				}

				&:after {
					border-color: $white;
				}
			}
		}
	}

	@include max-desktop() {
		.news-grid {
			.news-item {
				max-height: 240px;
			}
		}
	}

	@include small-desktop() {
		.news-grid {
			@include flex();
			@include flex-wrap(wrap);

			.news-item {
				max-height: 190px;

				&.news-small,
				&.news-large {
					width: 33.333%;
					overflow: hidden;
				}
			}
		}
	}

	@include max-tablet() {
		.news-grid {
			.news-item {
				max-height: none;
				margin: 40px 20px;

				.overlay {
					.background {
						&:before {
							top: -10px;
							height: 65px;
							width: 120%;
							left: -5%;
						}
					}
				}

				&.news-small,
				&.news-large {
					width: 50%;
				}
			}
		}
	}

	@include max-mobile() {
		.news-grid {

			.news-item {

				&.news-small,
				&.news-large {
					width: 100%;
				}
			}
		}
	}
}

.enquire-form.enquire-form-wrapper {
	margin: 80px auto;
}

.enquire-form-pod {
	@include border-radius(5px);
	padding: 0 !important;
}

.newsletter-form {
	position: relative;
	// @include border-radius(5px);
	margin-top: -45px;
	margin-bottom: 80px;
	padding: 30px 32px;
	background-color: $green;

	@include small-desktop {
		padding-right: 22px;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
		height: 20px;
		background-color: $green;
		transform: translateY(10px) rotate(.6deg);
	}

	h2 {
		@include Bree(bold-italic);
		@include font-size(30);
		color: $white;
	}

	form {

		.umbraco-forms-submitmessage {
			padding: 0 10px;
			padding-top: 20px;
			max-width: 1200px;
			margin: 0 auto;
			color: $white;
		}

		.umbraco-forms-container {
			display: flex;
			flex-wrap: wrap;
			// justify-content: flex-end;
			.richtext {
				width: (percentage((11*$column+20*$margin)/$max-width));
				position: absolute;
				left: 40px;
				bottom: 0;

				p {
					margin-bottom: 0;

					a {
						color: $black;
					}
				}
			}

			.shortanswer {
				flex-grow: 1;
				margin-right: 10px;
			}

			.checkbox {
				width: (percentage((12*$column+22*$margin)/$max-width));

				.umbraco-forms-label {
					display: none;
				}

				.checkbox-wrapper {
					label {
						margin: 0;
					}
				}

				@include max-tablet() {
					margin: 30px 0;
				}
			}
		}

		.button {
			background-color: $navy;
			color: white;

			&:hover {
				background-color: transparent;
				border: 2px solid $navy;
				color: $navy;
			}
		}
	}
}

.workwith-pod {
	text-align: center;
	padding: 80px 20px;
	background-color: white;

	h2 {
		@include Bree(bold-italic);
		@include font-size(36);
		color: var(--turquoise);
	}

	.intro {
		width: 90%;
		max-width: 595px;
		margin: 0 auto;
	}

	.workwith-carousel {
		margin: 40px 0;

		@media only screen and (min-width: 768px) {
			margin: 70px 0;
		}

		div {
			margin: 0 20px;

			@media only screen and (min-width: 1200px) {
				margin: 0 40px;
			}

			img {
				max-height: 130px;
				max-width: 170px;
			}
		}

		.slick-arrow {
			background-color: $turquoise;
			position: absolute;
			right: 10px;
			left: auto;
			top: 50%;
			transform: translateY(-50%);

			img {
				width: 20px;
				height: 20px;
			}

			&.prev {
				left: 10px;
				right: auto;

				img {
					transform: rotate(180deg);
				}
			}
		}
	}

	.slick-track {
		display: flex;
	}

	.slick-track .slick-slide {
		display: flex;
		height: auto;
		align-items: center;
		justify-content: center;
	}

	.work-slider-dots {
		@include flex();
		@include flex-justify(center);

		.slick-dots {
			order: 1;
			bottom: 0;
			position: static;
			width: auto;
		}

		.slider-controls {
			order: 2;
		}

		.slider-control {
			@include flex();
			@include flex-align(center);
			@include flex-justify(center);
			width: 34px;
			height: 44px;
			background-color: transparent;
			margin: 0 5px;
			padding: 0;

			& .mobile {
				display: none;
			}

			&.hidden {
				display: none;
			}

			&.play img {
				width: 9.3px;
				height: 11px;
			}

			&.pause img {
				width: 9px;
				height: 10px;
			}

			@media only screen and (max-width: 599px) {
				width: 22px;
				height: 22px;
				background-color: $navy;

				& .mobile {
					display: block;
				}

				& .desktop {
					display: none;
				}
			}
		}

		li {
			width: 34px;
			margin: 0 5px;
			height: 44px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			button {
				background-color: #b0b0b0;
				border-radius: 0;
			}

			&.slick-active {

				button {
					background-color: $turquoise;
				}
			}
		}
	}
}

.employerfeedback-pod {
	text-align: center;
	padding: 80px 20px;
	background: #2D3F49 url(../images/triangles.png) no-repeat bottom left;

	h2 {
		@include Bree(bold-italic);
		@include font-size(36);
		color: $turquoise;
		margin-bottom: 45px;
	}


	.feedback {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.feedback-item {
			width: 100%;
			display: flex;
			flex-direction: column;
			background: white;
			margin-bottom: 15px;


			.img {
				background: white;
				padding: 30px;
				display: flex;
				align-items: center;
				height: 250px;
				justify-content: center;

				img {
					max-width: 175px;
					max-height: 145px
				}
			}

			.content {
				background: #EEEEEE;
				position: relative;
				padding: 20px 25px 50px;
				flex: 1;

				&:before {
					content: "";
					position: absolute;
					top: -35px;
					left: 0;
					background-image: linear-gradient(to right top, #EEEEEE 0%, #EEEEEE 50%, transparent 50%);
					position: absolute;
					width: 100%;
					height: 35px;
					left: 0;
				}

				.name {
					@include Bree(bold-italic);
					@include font-size(24);
					color: var(--turquoise);
					margin-bottom: 15px;
				}
			}

			&:nth-child(even) {

				.content {

					&:before {
						background-image: linear-gradient(to left top, #EEEEEE 50%, #EEEEEE 0%, transparent 0%);
					}
				}
			}

			@media only screen and (min-width: 768px) {
				width: 45%;

				&:nth-child(odd) {
					margin-right: 15px;
				}
			}

			@media only screen and (min-width: 980px) {
				width: 23%;
				margin-right: 15px;

				&:last-child() {
					margin-right: 0;
				}
			}
		}
	}

	@media only screen and (min-width: 768px) {
	}

	@media only screen and (min-width: 980px) {
	}
}