/*** STATIC BUILD ONLY **/
.jump-nav {
	position: fixed;
	bottom: -300px;
	right: 0;
	height: 280px;
	width: auto;
	width: 200px;
	overflow: auto;
	padding: 0;
	cursor: pointer;
	text-align: right;
	@include transition(bottom 0.3s ease);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: left;

		li {
			&:first-child {
				a {
					border: none;
					@include border-radius(5px 0 0 0);
				}
			}
		}
	}

	&:hover,
	&.active {
		bottom: 0 !important;
	}

	.tab {
		display: inline-block;
		color: $white;
		font-size: 16px;
		line-height: 16px;
		padding: 15px;
		background-color: rgba(0,173,239,0.75); // 00adef
		border-bottom: 1px solid rgba(255,255,255,0.75);
		@include border-radius(5px 0 0 0);
	}

	a {
		width: 100%;
		display: inline-block;
		font-size: 16px;
		line-height: 16px;
		padding: 15px;
		color: $white;
		text-decoration: none;
		background-color: rgba(0,173,239,0.75); // 00adef
		@include transition(background-color 0.3s ease);
		border-top: 1px solid rgba(255,255,255,0.75);

		&:hover {
			background-color: rgba(0,173,239,0.95); // 00adef
		}
	}
}

/*** END STATIC BUILD ONLY ***/
.nav-span {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/*** Header Primary Nav ***/
#primary-nav {
	position: static;

	ul {
		font-size: 0;
		margin-bottom: 0;

		li {
			position: static;
			@include font-size(16);
			margin-right: 20px;

            html.no-touch & {
                &:hover {
                    .sub {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
		}
	}

	/* Effects immediate children only */
	> ul {
		> li {
			display: inline-block;

            @include max-tablet() {
                display: block;
                padding: 15px 10px;
                margin: 0;

                &.has-child {
					a {
						width: 100%;

						&:after {
							content: '';
							position: absolute;
							top: 50%;
							margin-top: -7px;
							right: -15px;
							background-color: transparent;
							width: 10px;
							height: 10px;
							border-top: 1px solid $black;
							border-right: 1px solid $black;
							@include rotate(45deg);
						}
					}
                }
            }
		}
	}

	&.hide {
		> ul {
			left: -100%;

			.sub {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

/* Header Primary Nav - Sub Nav */
.sub {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 15px;
	visibility: hidden;
	opacity: 0;
	@include transition(all 0.3s ease);

	@include max-desktop() {
		padding: 15px 0;
	}

	.row {
		padding: 15px 0;
		background-color: $white;
	}

	ul {
		li {
			display: block;

			&:last-child {
				padding: 0;
			}

			@include min-desktop() {
				display: none;
			}
		}
	}

    @include max-tablet() {
        left: 100%;
        opacity: 1;
		top: 0;

		.row {
			background-color: transparent;

			[class^=span] {
				width: 100%;
				margin: 0;
			}
		}

		> ul > li {
			pointer-events: none;
		}
    }
}

/* Full screen nav experiment */
#full-screen-nav {
	position: fixed;
	height: 100%;
	width: 100%;
	left: -100%;
	top: 0;
	background-color: rgba(0,0,0,0.5);
	@include transition(left 0.3s ease);

	&.open {
		left: 0;
	}
}

.pos-wrapper {
	position: absolute;
	/*transform: translateX(-220px);*/
}

.top-level-ul {
	display: flex;
	overflow: visible;
    visibility: visible;
}

.nav {
	width: 100%;
	height: 2.5em;
	line-height: 2.5em;

	overflow: hidden;
	-ms-overflow-style: none; 

	/* 
		height maintained by placeholder. 
		this makes it easy to eventually make this menu position:fixed (if we ever want to do that)
	*/
	position: absolute;
	z-index: 1;

	&:focus-within {
		overflow: visible;
	}

	.nav__inner-wrap {
		width: 100%;
		max-width: 700px;

		li:first-child {
			margin-left: 50px;
		}
	}

} 

/* hide horizontal scrollbar */ 

.nav ::-webkit-scrollbar { width: 0; }
/* 
	allow dropdown menus to be displayed below bar 
	by allowing their tall (height: $nav-dropdown-max-height) containers to be visible 
*/
.nav--hovered, .nav--focused {
	overflow: visible;
}

.nav__placeholder {
	height: 2.5em;
}

.nav__outer-wrap {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	position: inherit;
}

/* Level 1 (header)
   ========================================================================== */
.nav__heading {
	display: inline-block;
	flex-shrink: 0;
	flex-grow: 1;
	height: 2.5em;

	/* Hack: this compensates for the margin-left on the nav__item:first-child */
	margin-right: -10em;
	z-index: 2 /* so leftmost nav__item doesn't cover it */
	
}
.nav__heading-caret {
	color: #666;
	font-size: 16px;
	margin: 0 -.5em;
}

.nav__inner-wrap {
	white-space: nowrap;
	display: flex;
}

.nav__item {
	height: 2.5em;
	flex-shrink: 0; /* for iOS */
}

/* Hack: ensure the first menu item has a little margin-left so it's not narrower 
			 than it's dropdown menu if it's the only menu item. This is compensated 
			 for by the nav__heading's margin-right */



/* so that items w/ children contain their dropdown menus */
.nav__item.nav__menu-item--has-children {
	position: relative;
}

/* space on right edge of .nav__inner-wrap below .nav__scroll--right */
.nav__item--right-spacer {
	display: none;
	padding: 0 calc(2em / 2);
	background-color: transparent !important;
}
.nav--scrollable .nav__item--right-spacer {
	display: block;
}

.nav__link {
	display: block;
	padding: 0 10px;
	cursor: pointer; /* needed for all menu items, even dropdown, so iOS devices can hover over them */
	vertical-align: top; /* really only needed for children of .nav__heading so they line up with other links */
}

.nav__link--has-dropdown {
	position: relative;
}

.nav__scroll {
	position: absolute;
	top: 0;
	z-index: inherit;
	padding: 0 0 .15em;
	width: 20px;
	color: var(--light-turq);
	background: white;
	line-height: 0;
	height: 20px; /* b/c font-size is doubled */
	cursor: pointer;
	opacity: 0;
	transition: opacity .3s;
	font-size: 42px;
    font-weight: bold;
    outline: none;
}

.nav__scroll:focus {
		opacity: 1;
	}
	
.nav__scroll--left {
	left: 0;
	/*border-right: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;*/
}
.nav__scroll--right {
	right: 0;
	padding-right: 10px;
	/*border-left: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;*/
}
.nav__scroll--visible {
	opacity: 1;
	transition: opacity .3s;
}

.nav__link--toplevel {
	/* prevent text color from changing AFTER background color change on top-level links (not dropdowns) */
	transition: none;
}

/* Levels 2 & 3 (header)
   ========================================================================== */
/* menu item hovered/focused */
.nav__item:hover, .nav__item--has-focus {
	background-color: rgb(139, 117, 166);
	color: white;
	border-bottom-color: rgb(139, 117, 166);
}
/* 
		tiny pseudolement to cover bottom edge of adjacent menu items to prevent accidental hover
		as mouse moves diagonally down from menu item to dropdown
	 */
.nav__item:hover .nav__link--has-dropdown:before, .nav__item--has-focus .nav__link--has-dropdown:before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		width: calc(100% + 3em);
		left: -1.5em;
		height: 1.2em;
		/*background-color: red;*/
	}
/* ensure tiny pseudoelement only shows up on left side (not right side) of final menu item */
.nav__item:hover .nav__link--has-dropdown:nth-last-child(2):before, .nav__item--has-focus .nav__link--has-dropdown:nth-last-child(2):before {
		width: calc(100% + 1.5em);
	}
.nav__item:hover .nav__dropdown, .nav__item--has-focus .nav__dropdown {
		display: block;
	}
.nav__item:hover .icon--dropdown, .nav__item--has-focus .icon--dropdown {
		fill: white;
	}
.nav__item:hover .nav__menu-item, .nav__item--has-focus .nav__menu-item {
		padding: 0;
		margin-left: 1em;
		line-height: calc(2.5em - 8px);
	}
.nav__item:hover .nav__menu-item > a, .nav__item--has-focus .nav__menu-item > a {
			display: inline-block;
		}
.nav__item:hover .nav__menu-item > a:hover, .nav__item:hover .nav__menu-item > a:focus, .nav__item--has-focus .nav__menu-item > a:hover, .nav__item--has-focus .nav__menu-item > a:focus {
				background-color: rgb(106, 70, 138);
			}
.nav__item:hover .nav__menu-item > a:active, .nav__item--has-focus .nav__menu-item > a:active {
				background-color: rgb(73, 23, 110);
			}

.nav__item--repeated {
	margin-left: 0 !important;
}

.nav__dropdown {
	display: none;
	position: absolute;
	z-index: 2;
	left: -1em;
	padding: 1em;
	background-color: inherit;
	box-shadow: 1px 1px 4px rgba(150,150,150,.7);
}

.nav__item--right-aligned-dropdown .nav__dropdown {
		left: auto;
		right: 0;
		top: 2.5em;
	}
.nav--scrollable .nav__item--right-aligned-dropdown .nav__dropdown {
	right: -2em;
}

/*Small Phones*/
@media only screen and (max-width: 479px) {

	/* entire site nav bar is scrollable */
	.nav__outer-wrap {
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
	}
}

/*Large Phones on up*/
@media only screen and (min-width: 480px) {

	/* nav is scrollable EXCEPT for heading (first item) */
	.nav__inner-wrap {
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
	}
	.nav__heading {
		background-color: #e0e0e0;
	}
}

@media screen and (min-width: 1200px) {
	.nav__outer-wrap {
		 position: relative;  /*to contain nav__dropdown  */
		 padding-right: 0;
	}
	.nav__heading {
		background-color: transparent;
	}
	.nav__link {
		padding: 0 1em;
	}
	/* remove link to see full menu (in footer) and the spacing for it */
	/*.nav__scroll {
		display: none;
	}*/
}
