/*** BUTTON Styles ***/

.button {
	display: inline-block;
	padding: 11px 17px 13px 16px;
	color: $white;
	@include Bree(bold-italic);
	text-decoration: none;
	@include border-radius(5px);
	@include font-size(18, 16);
	@include transition(all .3s ease);
	border: 2px solid transparent;

	&.button-green {
		background-color: var(--light-turq);
		color: $white;

		&:hover,
		&:focus {
			background-color: transparent;
			border: 2px solid var(--light-turq);
			color: var(--light-turq);
		}

		body.high-contrast & {
			&:hover,
			&:focus {
				background-color: var(--turquoise);
				border: 2px solid var(--turquoise);
				color: $white;
			}
		}
	}

	&.button-navy {
		background-color: $navy;
		color: white;

		&:hover {
			background-color: transparent;
			border: 2px solid $navy;
			color: $navy;
		}
	}

	&.button-purple {
		background-color: $purple;
		color: $white;

		&:hover,
		&:focus {
			background-color: transparent;
			border: 2px solid $purple;
			color: $purple;
		}
	}

	&.button-yellow {
		background-color: var(--yellow);
		color: $purple;

		&:hover,
		&:focus {
			background-color: transparent;
			border: 2px solid $purple;
			color: $purple;
		}

		body.high-contrast & {
			color: $white;

			&:hover,
			&:focus {
				color: $white;
			}
		}
	}

	&.button-orange {
		background-color: var(--orange);
		color: $white;

		&:hover,
		&:focus {
			background-color: transparent;
			border: 2px solid var(--orange);
			color: var(--orange);
		}
	}

	&.button-white {
		background-color: $white;
		color: var(--light-turq);

		&:hover,
		&:focus {
			background-color: transparent;
			border: 2px solid $white;
			color: $white;
		}
	}
}

.link {
	display: inline-block;
	@include Bree(bold-italic);
	@include font-size(16);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&:after {
		content: '';
		display: inline-block;
		width: 5px;
		height: 5px;
		border-right: 1px solid $navy;
		border-bottom: 1px solid $navy;
		position: relative;
		top: 50%;
		@include transform(rotate(-45deg) translateY(-50%));
		margin-left: 6px;
	}
}
