// Widths
$column: 70;
$margin: 15;
$max-width: (1170 + $margin*2);

//1380 wide with 15px margin means $column = 85

// Breakpoints (these area defaults)
// Change per project

$base-font-size: 16;
$max-desktop: 1200px; // this is the same as $max-width, but it includes px unit.
$small-desktop: 980px;
$min-desktop: 769px;
$max-tablet: 768px;
$min-tablet: 569px;
$max-mobile: 568px;

$max-mobile-nav: 1024px; // you can use this if your mobile nav will look completely different to your desktop nav and you'd like to separate that sass

// Colours

$white: #fff;
$grey-1: #ddd;
$grey-2: #aaa;
$black: #000;
$ed-blue: #00adef;
$ed-blue-dark: #0080b3;
$ed-blue-darker: #00638a;

$yellow: #FDBA30;
$brown: #B5A064;
$orange: #F1664D;
$purple: #531E43;
$turquoise: #06AC8B;
$light-turq: #69C7B8;
$green: #70C27A;
$light-green: #96CF90;
$grey: #747578;
$grey-2: #535456;
$grey-3: #58595b;
$navy: #022F3A;

/* Default standard contrast */
:root {
  --turquoise: #06AC8B;
  --orange: #F1664D;
  --green: #70C27A;
  --light-green: #96CF90;
  --light-turq: #69C7B8;
  --brown: #B5A064;
  --yellow: #FDBA30;
}

$turquoise-high-contrast: #022F3A;
$light-turq-high-contrast: #157C6C;
$green-high-contrast: #21702B;