.news-listing-filters {
	margin: 40px 0;
	clear: both;

	h2 {
		@include Bree(bold-italic);
		color: $navy;
		@include font-size(24);
		margin: 0;
		padding-top: 5px;
	}

	form {
		text-align: right;
		fieldset {
			width: 185px;
			display: inline-block;

			.select-wrapper {
				.select-arrow {
					&:before {
						left: -8px;
					    position: relative;
					}
				}
			}

			&:last-of-type {
				margin-left: 5px;
			}
		}
	}

	@include max-tablet() {
		[class^=span] {
			width: 100%;

			form {
				text-align: left;
				margin-top: 25px;
				fieldset {
					width: 48.2%;

					.select-wrapper {
						.select-arrow {
							&:before {
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	@include max-mobile() {
		margin: 0 0 15px;
		[class^=span] {
			form {
				fieldset {
					width: 100%;

					&:last-of-type {
						margin: 0;
					}

					.select-wrapper {
						.select-arrow {
							width: 12px;
							&:before {
								top: 3px;
								left: -1px;
							}
						}
					}
				}
			}
		}
	}
}

.news-listing {

	& > .row {
		@include flex();
		@include flex-wrap(wrap);
	}

	.news-item {
		margin-bottom: 30px;
		overflow: hidden;
		@include flex();
		@include flex-direction(column);
		align-items: stretch;

		&.resources {
			.news-image {
				.shape {
					background-color: var(--orange);
					@include transform(rotate(-3deg));
					bottom: -10px;
				}
			}

			.news-content {
				h4,
				a {
					color: var(--orange);

					&:after {
						border-color: var(--orange);
					}
				}
			}
		}

		.news-image {
			position: relative;
			// overflow: hidden;

			.news-image-inner {
				position: relative;
				overflow: hidden;
			}

			img {
				display: block;
				width: 100%;
			}

			.shape {
				position: absolute;
				bottom: -20px;
				left: -10%;
				right: 0;
				width: 120%;
				height: 60px;
				background: var(--turquoise);
				opacity: 0.8;
				@include transform(rotate(3deg));
				backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
			}

			.image-overlay {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 11;
				padding-bottom: 9px;
				padding-left: 20px;

				.category {
					@include Din(bold);
					@include font-size(15);
					color: $white;
					text-transform: capitalize;
				}
			}
		}

		.news-content {
			background-color: $white;
			padding: 25px;
			height: 100%;
			@include flex();
			@include flex-direction(column);

			.date {
				@include Din(demi);
				@include font-size(14);
				color: $navy;
			}

			h4 {
				@include Bree(bold-italic);
				@include font-size(21);
				color: var(--turquoise);
			}

			p {
				@include Din(regular);
				@include font-size(16);
				color: $navy;
			}

			a {
				color: var(--turquoise);
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}

				&:after {
					border-color: var(--turquoise);
					position: inherit;
				}

				&.link {
					margin-top: auto;
				}
			}
		}
	}

	@include small-desktop() {
		[class^=span] {
			width: 47.5%;
		}
	}

	@include max-mobile() {
		.news-item {
			margin-bottom: 15px;

			.news-content {
				height: auto;
			}
		}
	}
}
