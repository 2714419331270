@charset "utf-8";

@import url("https://use.typekit.net/lpy6eem.css");

@import "modules/reset";		// Basic reset
@import "modules/constants";	// Widths, heights etc all re-usable
@import "modules/high-contrast";	// High-Contrast overrides
@import "modules/mixins";		// Re-usable mixins - CSS3 etc..
@import "modules/typography";	// Font declarations
@import "modules/grid12";		// Basic grid structure
@import "modules/buttons";		// Button styles
@import "modules/richtext";		// Richtext formatting styles
@import "modules/hero";			// Hero Area styles
@import "modules/forms";		// Form styles
@import "modules/content";		// General content styles
@import "modules/sliders";		// Slider / carousel styles
@import "modules/cookiebot";    // Cookiebot

@import 'modules/pods';

@import 'modules/chosen';


@import "layout/base";
@import "layout/header";		// Site header
@import "layout/footer";		// Site footer
@import "layout/navigation";	// Site nav

@import 'pages/home';
@import 'pages/course-search';
@import 'pages/course';
@import 'pages/career-listing';
@import 'pages/search-results';
@import 'pages/news-listing';
@import 'pages/event-listing';
@import 'pages/event';
@import 'pages/contact';
@import 'pages/profile-listing';
@import 'pages/profile-detail';
@import "pages/job-listing.scss";