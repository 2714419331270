.contact-intro {
	background-color: $white;
	@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
	margin-bottom: 50px;
	padding: 35px 30px;

	h2 {
		@include Bree(bold-italic);
		color: var(--turquoise);
		@include font-size(30);
	}

	p {
		@include Din(regular);
		color: $navy;
		@include font-size(17);
		width: 100%;
	}

	.contact-address {
		@include flex();
		@include flex-wrap(wrap);

		.contact-details {
			margin-top: 20px;
			padding-right: 10px;

			&:nth-child(4n + 4) {
				padding-right: 0;
			}

			&:nth-child(4n + 1) {
				color: var(--orange);

				address {
					&:before {
						color: var(--orange);
					}
				}

				.link {
					&:after {
						border-color: var(--orange);
					}
				}
			}

			&:nth-child(4n + 2) {
				color: $purple;

				address {
					&:before {
						color: $purple;
					}
				}


				.link {
					&:after {
						border-color: $purple;
					}
				}
			}

			&:nth-child(4n + 3) {
				color: var(--yellow);

				address {
					&:before {
						color: var(--yellow);
					}
				}


				.link {
					&:after {
						border-color: var(--yellow);
					}
				}
			}

			&:nth-child(4n + 4) {
				color: var(--turquoise);

				address {
					&:before {
						color: var(--turquoise);
					}
				}


				.link {
					&:after {
						border-color: var(--turquoise);
					}
				}
			}

			span {
				display: block;
				width: 100%;

				&.contact-title {
					color: inherit;
					@include Bree(bold-italic);
					@include font-size(25);
					margin-bottom: 20px;
				}

				&.contact-email,
				&.contact-tel {
					@include Din(bold);
					color: inherit;
					@include font-size(17);

					&:before {
						content: '\f003';
						font-family: 'FontAwesome';
						color: inherit;
						@include font-size(16);
						line-height: 22px;
						display: block;
						float: left;
						margin-right: 14px;
						width: 15px;
						height: 20px;
					}

					&:hover {
						text-decoration: underline;
						text-decoration-color: inherit;
					}
				}

				&.contact-tel {
					margin-bottom: 20px;
					&:before {
						content: '\f095';
						margin-right: 14px;
					}
				}
			}

			address {
				@include Din(regular);
				color: $navy;
				@include font-size(17);
				padding-left: 27px;
				position: relative;

				&:before {
					content: '\f041';
					font-family: 'FontAwesome';
					color: inherit;
					@include font-size(21);
					line-height: 27px;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			a {
				text-decoration: none;
				color: inherit;

				&.link {
					margin-top: 10px;
					margin-left: 27px;
				}

				&:hover {
					text-decoration: underline;
					text-decoration-color: inherit;
				}
			}
		}
	}


	@include small-desktop() {
		.contact-details {
			&.span3.expand {
				width: 50%;
			}
		}
	}

	@include max-mobile() {
		.contact-details {
			&.span3.expand {
				width: 100%;
				margin-bottom: 25px;
			}
		}
	}
}

.map {

	.map-title {
		background-color: var(--green);
		color: $navy;
		@include Bree(bold-italic);
		@include font-size(30);
		padding: 35px;
		position: relative;
		z-index: 4;

		body.high-contrast & {
			color: $white;
		}
	}

	.the-map {
		margin-top: -50px;

	}
}

#gmap { //Also used on the events page.
    width: 100%;
    height: 550px;
}

.gm-style .gm-style-iw {
    background-color: var(--orange) !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
	min-width: 270px;
    max-height: 100px !important;
    padding: 20px;
    display: block !important;

	& > div {
		width: 100%;
	}

	div {
		max-height: 85px !important;
	}

	p {
		@include Bree(bold-italic);
		@include font-size(23);
		color: $white;
		margin: 0 0 5px;
	}
 }

.gm-style div div div div div div div div {
	background-color: var(--orange) !important;
	padding: 0;
	margin: 0;
	padding: 0;
	top: 0;
	color: #fff;
	font-size: 16px;
	@include Bree(bold-italic);
}

.register-interest {
	h2 {
		@include Bree(bold-italic);
		@include font-size(30);
		color: $navy;
		margin-bottom: 20px;

		body.high-contrast & {
			color: $white;
		}
	}
}

.contact-form,
.enquire-form {
	background-color: var(--light-turq);
	margin-top: -50px;
	position: relative;
	z-index: 4;
	padding: 60px 100px;
	@include box-shadow(0, 1px, 4px, 0, #d7d7d7);

	h2 {
		@include Bree(bold-italic);
		@include font-size(30);
		color: $navy;
		margin-bottom: 20px;

		body.high-contrast & {
			color: $white;
		}
	}

	p {
		color: white;
	}

	.button {
		background-color: $navy;
		color: white;
		border: 2px solid $navy;
		float: right;

		&:hover,
		&:focus {
			background-color: transparent;
			color: $navy;
		}

		&.submitted {
			background-color: transparent;
			border: 2px solid $navy;
			color: $navy;
		}

		body.high-contrast & {
			background-color: $white;
			color: $navy;
			border: 2px solid $white;

			&:hover,
			&:focus {
				background-color: transparent;
				color: $white;
			}

			&.submitted {
				background-color: transparent;
				border: 2px solid $white;
				color: $white;
			}
		}
	}

	.umbraco-forms-submitmessage {
		color: $navy;

		body.high-contrast & {
			color: $white;
		}
	}

	label,
	legend,
	.field-validation-error {
		body.high-contrast & {
			color: $white;
		}
	}

	@include max-tablet() {
		padding: 30px 20px;
	}

	@include max-mobile() {
		form {
			.row {
				[class^=span] {
					width: 100%;
					margin: 0;
				}
			}
		}
	}
}
