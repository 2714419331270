//Font mixins

//Din 2014
// @include Din(light);
// @include Din(demi);
// @include Din(bold);
// @include Din(bold-italic);
// @include Din(regular);

//Bree
// @include Bree(bold);
// @include Bree(bold-italic);
// @include Bree(extra-bold);

//Source Sans Pro
// @include SourceSans(regular);
// @include SourceSans(semi-bold);
// @include SourceSans(bold);


/*** replace with project specific fonts ***/

body {
	font-family: Arial, Helvetica, sans-serif;
}

html {
	font-size: 16px;
	font-weight: 100;
	line-height: 1.5rem;

	@include max-mobile() {
		font-size: 14px;
	}
}

$FontAwesome: 'FontAwesome';

h1, h2, h3, h4, h5, h6, p, ul, ol {
	margin-top: 0;
	margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 1rem;
}

h1 {
	margin-bottom: 2rem;
}

h2 {
	margin-bottom: 1.5rem;
}

h3 {

}

h4 {
	@include Din(demi);
	@include font-size(24);
	color: #2c4144;
}

p {
	@include Din(regular);
	@include font-size(17, 25);
}

strong {
	@include Din(bold);
	@include font-size(18, 21);
}
