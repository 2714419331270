/*** Global and page-specific form style ***/

.standard-form {
	[class^=span] {
		margin-bottom: (percentage($margin/$max-width));
	}

	span {
		@include font-size(16,24);
		display: block;
		margin-bottom: 5px;
	}

	.button {
		max-height: 38px;
		line-height: normal;
	}
}

.rich-text {
	.standard-form {
		margin-left: (-(percentage($margin/$max-width)));
		margin-right: (-(percentage($margin/$max-width)));
	}
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

label {
	display: block;
	margin-bottom: 5px;

	&.hidden {
		visibility: hidden;
		height: 0 !important;
		width: 0 !important;
		font-size: 0 !important;
		margin: 0 !important; // override custom styles
	}
}

button {
	border: none;
}

input {
	display: block;
	width: 100%;
	padding: 8px;
	@include placeholder-color(rgba(0,0,0,0));

	&[type=radio],
	&[type=checkbox] {
		height: auto;
	}

	&[type=submit] {
		width: auto;
		border: none;
	}

	&[type="search"] {
		-webkit-appearance: inherit;
		font-size: 16px !important;
	}
}

input,
select {
	height: 45px;
	border: 1px solid #d7d7d7;
	@include border-radius(3px);
	background-color: $white;
	@include placeholder-color($grey);
	padding: 0 10px;
	color: $grey;
	@include Din(regular);
	@include font-size(16, 17);
}

input,
textarea,
select,
button,
.radio-wrapper,
.checkbox-wrapper {
	@include max-mobile() {
		margin-bottom: 15px;
	}
}

textarea {
	width: 100%;
	border: 1px solid #d7d7d7;
	@include border-radius(3px);
	background-color: $white;
	@include placeholder-color($grey);
	padding: 10px;
	color: $grey;
	@include Din(regular);
	@include font-size(16, 17);
	min-height: 175px;
	resize: none;
}

.select-wrapper,
select {
	width: 100%;
	outline: none;
}

select {
	padding: 8px;
	cursor: pointer;
	color: $grey;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: none;

	option:disabled {
		color: rgba(116, 117, 120, 0.6);
	}
}

.select-wrapper {
	position: relative;

	// Included in mark-up for accessibility
	label {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}

	.select-arrow {
		position: absolute;
		right: 5px; /* for border */
		bottom: 1px; /* for border */
		width: 26px;
		height: 43px; /* height of select-wrapper, minus 2 for border */
		background-color: $white;
		pointer-events: none;
		z-index: 1; //To overlay Chosen dropdowns

		&:before {
			content: '\f107';
			vertical-align: middle;
			width: 100%;
			color: $grey;
			font-family: $FontAwesome;
			@include font-size(25); /* line-height is height of select-arrow */
			line-height: 45px; //pixel val for windows 10 ie11;
			@include transition(all .3s ease);
			position: absolute;
		}

		&.active {
			&:before {
				@include transform(rotate(180deg) translateX(5px));
			}
		}

		/** NOTE: be sure to watch out for changes in heights based on changes in typograhpy sizes **/
		@include max-mobile() {
			bottom: 16px;
		}
	}

	// <= IE10
	html.no-webgl.no-flexboxlegacy & {
		.select-arrow {
			display: none;
		}
	}
}

input[type=radio],
input[type=checkbox] {
	position: absolute;
	left: -9999px;
}

.radio-wrapper,
.checkbox-wrapper {
	label {
		position: relative;
		padding-left: 30px;
		@include SourceSans(semi-bold);
		@include font-size(15, 29);

		&:before {
			content: '';
			position: absolute;
			width: 22px;
			height: 22px;
			top: 3px;
			left: 0;
			background-color: $white;
			border: 0;
		}
	}

	// Inline styles
	&.inline {
		label {
			display: inline-block;
			margin-right: 30px;
		}
	}

	input[type=radio]:focus + label,
	input[type=checkbox]:focus + label {
		&:before {
			box-shadow: 0 0 0 4px $navy;
		}
	}
}

.radio-wrapper {
	label {
		&:before {
			@include border-radius(100%);
			// border: 1px solid $grey-2;
			// width: 85%;
		}
	}

	input[type=radio]:checked + label,
	input[type=radio]:checked + input + label {
		&:before {
			background-color: $navy;
		}
	}

	input[type=radio]:focus-visible + label {
		&:before {
			box-shadow: 0px 0px 0px 2px $black, 0px 0px 0px 4px $white;
		}
	}
}

.checkbox-wrapper {
	label {
		&:before {
			content: '';
			color: $navy;
			font-family: $FontAwesome;
			font-size: 14px;
			line-height: 24px;
			@include border-radius(3px);
			text-align: center;
		}
	}

	input[type=checkbox]:checked + label,
	input[type=checkbox]:checked + input + label {
		&:before {
			content: '\f00c';
		}
	}

	input[type=checkbox]:focus-visible + label {
		&:before {
			box-shadow: 0px 0px 0px 2px $black, 0px 0px 0px 4px $white;
		}
	}

	// <= IE 11
	html.no-flexboxlegacy & {
		label {
			line-height: 45px !important;
			position: relative;

			&:before {
				top: 11px;
			}
		}
	}
}

.search-area {

	&.cancel-transitions {
		@include cancel-transitions();
	}
}

.search-icon {
	background-color: transparent;
	background-image: url(../images/icons/Search_Icon.png);
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 21px;
	height: 21px;
	width: 21px;
	cursor: pointer;
	position: relative;
	z-index: 2;
	float: right;

	&.active {
		background-image: none;

		&:before {
			content: '\f00d';
			font-family: $FontAwesome;
			color: $white;
			@include font-size(21);
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.search-form {
	position: relative;
	@include placeholder-color(rgba(0,0,0,0));

	// Included in mark-up for accessibility
	label {
		visibility: hidden;
		height: 0;
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	input {
		float: right;
		background-color: transparent;
		background-image: url(../images/icons/Search_Icon.png);
		background-position: right center;
		background-repeat: no-repeat;
		background-size: 21px;
		font-size: 16px;
		height: 21px;
		min-height: 0;
		line-height: 21px !important;
		padding-top: 0;
		padding-left: 0;
		padding-bottom: 0;
		padding-right: 25px;
		border: none;
		border-left: 1px solid $black;
		width: 31px;
		cursor: pointer;
		@include transition(all 0.3s ease);
		color: rgba(255,255,255,0);
		position: relative;
		z-index: 2;

		&:focus {
			width: 150px;
			border-left: none;
			color: $black;
			z-index: 0;
		}

		@include max-mobile() {
			font-size: 16px;
		}
	}

	input[type=submit] {
        position: absolute;
        width: 31px;
        height: 21px;
        opacity: 0;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0;
        border: none;
        z-index: 1;
    }

	&:after {
		@include cf();
	}
}

.large-search-form {
	@include flex();
	@include flex-align(center);
	@include flex-justify(center);
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	@include transform-origin(100% 0);
	@include transform(scale(0,0));
	background-color: rgba(0,0,0,0.9);
	@include transition(all 0.5s ease 0.5s);

	// Included in mark-up for accessibility
	label {
		visibility: hidden;
		height: 0;
	}

	// IE9 fix
	html.no-flexbox & {
		display: block;
		padding-top: 40vh;

		fieldset {
			display: block;
			margin: 0 auto;
		}
	}

	fieldset {
		width: 300px;
		border: none;
		border-bottom: 3px solid $white;
		padding: 0 0 5px 0;
		opacity: 0;
		@include transition(opacity 0.3s ease);
		@include placeholder-color(rgba(255,255,255,0.5));

		@include max-mobile() {
			width: 75%;
		}

		input {
			background-color: transparent;
			border: none;
			color: $white;
			float: left;
			height: auto;
			padding: 0;
			$emToCalc: em(21);
			@include calc(width, "100% - " + $emToCalc);

			&[type=submit] {
				background-image: url(../images/icons/Search_Icon.png);
				background-position: right center;
				background-repeat: no-repeat;
				background-size: 21px;
				width: 21px;
				height: 21px;
				text-indent: -2000px;
			}
		}
	}

	&.active {
		@include transform(scale(1,1));
		@include transition(all 0.5s ease);

		fieldset {
			opacity: 1;
			@include transition(opacity 0.3s ease 0.75s);
		}
	}
}

.search {
	form {
		input[type="search"] {
			display: block;
			width: 100%;
			height: 50px;
			padding: 5px 10px 5px 65px;
			background-color: $white;
			border-top: 0;
			border-right: 0;
			border-left: 0;
			border-bottom: 2px solid #747578;
			box-sizing: border-box;
			opacity: 0;
			visibility: hidden;
			@include transition(all .3s ease);
			@include placeholder-color(#bcbdbd);
			@include Din(demi);
			color: #747578;
			@include font-size(16);
			@include border-radius(0);
		}

		.search-label {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			font-size: 16px;
			line-height: 20px;
			opacity: 0;
			visibility: hidden;
			@include transition(all .3s ease);
			@include Din(demi);
			color: #747578;
			@include font-size(18);

			@include max-tablet() {
				color: $white;
				left: 20px;
				@include font-size(18);
			}
		}
	}

	&.active {
		form {
			input[type="search"],
			.search-label {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

//AutoComplete Search
.autocomplete-suggestions {
	margin-top: 15px;
	background-color: $white;
	@include box-shadow(0, 1px, 3px, 1px, #969696);
	padding: 15px;

	&:before {
		content: '\f0d8';
		font-family: 'FontAwesome';
		text-shadow: 0 -2px 3px #969696;
		position: absolute;
		top: -26px;
		left: 30px;
		width: 20px;
		height: 20px;
		color: $white;
		@include font-size(35);
		line-height: 41px;
	}

	.autocomplete-suggestion {
		@include Din(normal);
		@include font-size(17);
		cursor: pointer;
		background-color: $white;
		@include transition(all .3s ease);
		padding: 5px;

		&:hover {
			background-color: #e4e4e4;
		}

		strong {
			@include Din(bold);
		}
	}
}

//Umbraco Forms LCB Theme

.lcb-theme {
	background-color: $light-turq;
	position: relative;
	z-index: 4;
	padding: 40px 32px 60px;
	@include box-shadow(0, 1px, 4px, 0, #d7d7d7);

	@include max-desktop() {
		margin-left: 15px;
		margin-right: 15px;
	}

	body.high-contrast & {
		background-color: $navy;
	}

	h2,
	.standard-content .standard-content-wrapper & h2 {
		@include Bree(bold-italic);
		@include font-size(30);
		color: $navy;
		margin-bottom: 20px;
	}

	.row {
		clear: both;
		@include flex();
	}

	legend {
		margin: 0 1.25%;
	}

	fieldset {
		margin: 15px 0;

		@include max-tablet() {
			margin: 0;
		}

		.input-validation-error {
			border: 2px solid #e85454;
			@include box-shadow(inset, 0, 0, 3px, #e85454);
			@include placeholder-color(#e85454);

			& + .field-validation-error {
				@include Din(bold);
			}
		}

		.field-validation-error {
			@include Din(bold);

			body.high-contrast & {
				color: $white;
			}
		}

		label {
			@include SourceSans(semi-bold);
			@include font-size(18);
			display: inline-block;
			color: $navy;
			margin-top: 8px;

			& + .tooltip {
				display: inline-block;
				width: 17px;
				height: 17px;
				@include border-radius(50%);
				background-color: $white;
				position: relative;
				margin-left: 7px;

				&.active {
					& span + .tooltip-content {
						display: block;
					}
				}

				span {
					display: block;
					width: 100%;
					height: 100%;
					color: $navy;
					@include Bree(bold);
					@include font-size(14, 17);
					cursor: pointer;
					text-align: center;
				}

				.tooltip-content {
					position: absolute;
					left: -90px;
					min-width: 200px;
					min-height: 45px;
					background-color: var(--orange);
					top: -14px;
					transform: translateY(-100%);
					z-index: 1;
					color: $white;
					display: none;
					@include Din(regular);
					@include font-size(16);
					padding: 10px;
					@include border-radius(5px);

					.close-tooltip {
						position: absolute;
						top: -12px;
						right: -12px;
						display: block;
						width: 25px;
						height: 25px;
						background: url("../images/icons/close-grey.png") center center no-repeat $white;
						background-size: 9px 10px;
						border: 3px solid var(--orange);
						@include border-radius(50%);
						color: $navy;
						@include Bree(bold);
						@include font-size(14, 16);
						display: none;
					}

					&:after {
						content: '\f0d7';
						position: absolute;
						bottom: -10px;
						width: 15px;
						height: 30px;
						@include font-size(40);
						color: var(--orange);
						text-align: center;
						font-family: 'FontAwesome';
						left: -10px;
						right: 0;
						margin: 0 auto;
					}
				}
			}

			&.hidden {
				display: block;
			}
		}

		input[type="text"],
		input[type="email"],
		input[type="tel"] {
			margin-bottom: 15px;
		}

		.checkbox {
			margin: 10px 0;

			& > label {
				line-height: 27px;
				cursor: pointer;
			}

			label {
				display: inline-block;

				& + .umbraco-forms-field-wrapper {
					float: left;
				}
			}

			.checkbox-wrapper {
				label {
					height: 24px;
				}
			}
		}

		.radio-wrapper {
			br {
				&:last-of-type {
					display: none;
				}
			}
		}

		.richtext {
			a {
				color: $navy;

				body.high-contrast & {
					color: $yellow;
				}
			}
		}
	}

	.form-pagination {
		margin: 30px 0;
		text-align: center;

		ul {
			display: inline-block;
			@include flex();
			@include flex-justify(center);

			li {
				display: inline;
				float: left;
				@include Bree(bold-italic);
				@include font-size(16);
				padding: 0 20px;
				position: relative;
				flex: 0 1 100px;

				@include max-tablet() {
					padding: 0 15px;
					flex: 0 1 50px;
				}

				@include max-mobile() {
					padding: 0 5px;
				}

				@include min-desktop() {
					flex: 0 1 80px;
				}

				&:first-of-type {
					padding-left: 0;

					.status {
						&:before {
							content: none;
						}
					}
				}

				&:last-of-type {
					padding-right: 0;

					.status {
						&:after {
							content: none;
						}
					}
				}

				.status {
					width: 24px;
					height: 24px;
					margin: 0 auto;
					@include border-radius(50%);
					background-color: $white;
					position: relative;

					&:before {
						content: '';
						width: 100px;
						position: absolute;
						top: 9px;
						left: -100px;
						height: 6px;
						background-color: $white;
					}

					&:after {
						content: '';
						width: 100px;
						position: absolute;
						top: 9px;
						right: -100px;
						height: 6px;
						background-color: $white;
					}

					&.active {
						.badge {
							content: '';
							width: 16px;
							height: 16px;
							display: block;
							position: absolute;
							top: 4px;
							left: 4px;
							margin: 0 auto;
							background-color: $navy;
							@include border-radius(50%);
							z-index: 2;
						}

						& + span {
							display: block;
							opacity: 1;
						}
					}
				}

				span {
					display: inline-block;
					margin-top: 10px;
					opacity: 0.6;
					color: $navy;

					body.high-contrast & {
						color: $white;
					}
				}
			}
		}

		h4 {
			margin-bottom: 30px;
		}

		@include max-desktop() {
			margin-left: -10px;
			margin-right: -10px;

			ul {
				li {
					padding: 0 15px;
				}
			}
		}

		@include small-desktop() {
			ul {
				position: relative;

				li {
					position: static;

					.status {

						&:before {
							width: 50px;
							left: -50px;
						}

						&:after {
							width: 50px;
							right: -50px;
						}
					}
				}
			}
		}
	}

	.button {
		background-color: $navy;
		color: white;
		border: 2px solid $navy;
		float: right;

		&:hover,
		&:focus {
			background-color: transparent;
			color: $navy;
		}

		&.submitted {
			background-color: transparent;
			border: 2px solid $navy;
			color: $navy;
		}

		body.high-contrast & {
			background-color: $white;
			color: $navy;
			border: 2px solid $white;

			&:hover,
			&:focus {
				background-color: transparent;
				color: $white;
			}

			&.submitted {
				background-color: transparent;
				border: 2px solid $white;
				color: $white;
			}
		}
	}

	@include max-mobile-nav() {
		fieldset {
			label {
				& + .tooltip {
					.tooltip-content {
						.close-tooltip {
							display: block;
						}
					}
				}
			}
		}
	}

	@include max-tablet() {
		.row {
			@include flex-wrap(wrap);

			[class^=span] {
				width: 100%;
				margin: 0;
			}
		}

		.form-pagination {
			ul {
				li {
					padding: 0 15px;

					.status {
						&.active {
							display: block;
						}

						&:before {
							width: 30px;
							left: -30px;
						}

						&:after {
							width: 30px;
							right: -30px;
						}
					}

					span {
						display: none;
					}
				}
			}
		}

		fieldset {
			label {
				& + .tooltip {
					position: static;

					.tooltip-content {
						left: 0;
						top: -5px;
						max-width: 100%;
						transform: translateY(-100%);

						&:after {
							content: none;
							display: none;
						}
					}
				}
			}
		}
	}

	@include max-mobile() {

		.form-pagination {
			ul {
				li {
					padding: 0;
				}
			}
		}

		fieldset {
			.checkbox {
				margin: 0;

				.checkbox-wrapper {
					margin-bottom: 10px;
				}
			}
		}
	}
}

.lcbNewsletter-theme {
	background-color: $green;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	box-shadow: none;

	.row {
		display: block;
	}

	.submit-lrg {
		margin-top: 38px;

		@include small-desktop {
			display: none;
		}
	}


	.submit-sml {
		display: none;
		margin-right: 20px;

		@include small-desktop {
			display: block;
		}

		@include max-mobile {
			margin-top: 20px;
			margin-right: 10px;
		}
	}
}

.umbraco-forms-submitmessage {
	@include Bree(bold-italic);
	@include font-size(24);
}
