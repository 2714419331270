/*** GENERAL Content Styles ***/

section {
	position: relative;

	@include max-desktop() {
		padding-left: 20px;
		padding-right: 20px;
	}

	@include max-mobile() {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.content {
	padding-top: 50px;
	padding-bottom: 50px;

	@include max-desktop() {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.standard-content {
	background-color: $white;
	padding: 55px 0;
	margin-top: -30px;
	margin-bottom: 45px;
	@include box-shadow(0, 1px, 4px, 0, #d7d7d7);

	@include max-desktop() {
		margin: 0 15px;
	}

	.standard-content-wrapper {
		max-width: 870px;
		margin: 0 auto;
		padding-bottom: 20px;

		.lcb-theme {
			fieldset {

				label {
					& + .tooltip {
						background-color: var(--orange);

						span {
							color: $white;
						}
					}
				}
			}

			h2,
			h3,
			h4,
			h5 {
				padding-left: 0;
			}
		}

		.article-details {
			margin-bottom: 10px;

			p {
				@include Din(bold);
				color: $grey;
				@include font-size(15);

				span {
					color: var(--brown);
				}
			}
		}

		.article-footer {

			.article-tags,
			.social-bar {
				float: left;
				width: 49.8%;
				padding: 0 20px;
			}

			.social-bar {
				p {
					@include Din(regular);
					@include font-size(15, 28);
					color: $grey;
					display: inline-block;
					margin: 0;
				}

				.social-links {
					float: right;

					a {
						display: inline-block;
						width: 24px;
						height: 22px;
						text-decoration: none;
						text-align: center;

						i {
							color: $white;
							display: block;
							width: 100%;
							height: 100%;
							@include font-size(14, 24);

							&.fa-facebook {
								background-color: #3d5b98;
							}

							&.fa-twitter {
								background-color: #1fa0f2;
							}

							&.fa-linkedin {
								background-color: #0177b4;
							}
						}
						/* provided for accessibility when stylesheets are off */
						span {
							display: none;
						}
					}
				}
			}

			.article-tags {
				p,
				a {
					display: inline-block;
					margin: 0;
					padding: 0;
					@include font-size(15);
				}

				p {
					@include Din(regular);
					color: $grey;
				}

				a.tag {
					@include Din(bold);
					color: $grey;
					text-decoration: none;

					&:nth-child(n + 2) {
						&:after {
							content: ', ';
							display: inline-block;
							padding-right: 3px;
						}
					}

					&:last-child,
					&:last-of-type {
						&:after {
							content: none;
						}
					}
				}
			}

			a.button {
				padding: 5px 19px 8px 15px;
				margin-top: 20px;
				clear: both;

				&:before {
					content: '';
					display: inline-block;
					border-left: 2px solid $white;
					border-bottom: 2px solid $white;
					@include rotate(45deg);
					position: relative;
					top: -1px;
					width: 5px;
					height: 5px;
					margin-right: 3px;
					@include transition(all .3s ease);
				}

				&:hover {
					&:before {
						border-color: var(--turquoise);
					}
				}
			}

			& > div:last-of-type {
				text-align: right;

				&.vacancy-links {
					text-align: center;

					@media (min-width: $min-tablet) {
						text-align: right;
					}
				}
			}

			& > div:first-of-type {
				text-align: left;

				&.social-bar {
					p,
					.social-links {
						clear: none !important;
						padding-left: 0;
					}
				}
			}
		}

		hr {
			background-color: var(--turquoise);
			border: 0;
			height: 2px;
			@include calc(width, '100% - 10px');
			margin: 30px auto;
		}

		h1,
		h2 {
			@include Bree(bold-italic);
			color: var(--turquoise);
			@include font-size(30, 38);
			padding: 0 20px;
		}

		h3 {
			@include Bree(bold-italic);
			color: var(--turquoise);
			@include font-size(24, 38);
			padding: 0 20px;
			display: inline-block;
		}

		h4 {
			@include Din(demi);
			color: #5c5c5c;
			@include font-size(20);
			padding: 0 20px;
		}

		p,
		ul,
		li,
		a,
		table,
		tr,
		td,
		em,
		ol {
			@include Din(light);
			color: $navy;
			@include font-size(18, 28);
			padding: 0 20px;
		}

		.caption {
			margin-top: -12px;
			position: relative;
			width: 85%;

			p {
				color: $white;
				@include Din(regular);
				@include font-size(13, 20);
				padding: 8px 20px;
				position: relative;
			}

			.shape {
				position: absolute;
				width: 100%;
				height: 100%;
				@include transform(rotate(-1deg));
				background-color: var(--turquoise);

				&:after {
					content: '';
					width: 45px;
					position: absolute;
					top: 0;
					right: -20px;
					height: 100%;
					@include transform(skew(-30deg));
					background-color: inherit;
				}
			}
		}

		strong,
		em {
			padding: 0;
		}

		a {
			padding: 0;
			color: var(--orange);
			text-decoration-color: var(--orange);

			&.button {
				color: inherit;
				text-decoration: none;
				@include Bree(bold-italic);
				color: $white;
				@include font-size(15);
				padding: 6px 17px 8px 16px;

				i {
					width: 7px;
					height: 7px;
					border-left: 2px solid $white;
					border-bottom: 2px solid $white;
					@include transform(rotate(45deg));
					display: inline-block;
					position: relative;
					top: -1px;
					margin-right: 5px;
				}

				&:hover,
				&:focus {
					color: var(--turquoise);

					i {
						border-color: var(--turquoise);
					}
				}

				body.high-contrast & {
					&:hover,
					&:focus {
						color: $white;

						i {
							border-color: $white;
						}
					}
				}
			}
		}

		iframe {
			display: block;
			width: 100%;
			min-height: 450px;
			margin: 15px 0;
		}

		ul,
		ol {
			margin-left: 45px;
			margin-bottom: 20px;

			li {
				@include Din(light);
				@include font-size(18, 28);
				color: $navy;
				padding: 0;
			}
		}

		blockquote {
			@include Bree(bold-italic);
			@include font-size(23, 26);
			width: 100%;
			margin: 30px 0;
			background-color: var(--orange);
			background-image: url('../images/icons/blockquote-bg.png');
			background-repeat: no-repeat;
			background-position: 105% -30px;
			padding: 30px 120px 30px 30px;
			color: var(--yellow);

			body.high-contrast & {
				color: $yellow;
			}

			a {
				color: var(--yellow);
				@include Bree(bold-italic);
				@include font-size(23, 26);

				body.high-contrast & {
					color: $yellow;
				}
			}

			p {
				color: var(--yellow);
				@include Bree(bold-italic);
				@include font-size(23, 26);
				margin: 0 0 5px 0;
				padding: 0;

				body.high-contrast & {
					color: $yellow;
				}
			}

			span {
				@include Din(regular);
				color: $white;
				@include font-size(15);
			}
		}

		table {
			padding: 0;
			width: 100%;
			margin: 25px 0;

			thead {
				tr {
					background-color: $purple;

					td,
					th {
						padding: 15px 20px;
						color: $white;
						@include Din(bold);
						@include font-size(16);
					}
				}
			}

			tbody {
				tr {
					background-color: #f1f1f1;

					&:nth-of-type(even) {
						background-color: #e8e8e8;
					}

					td {
						@include Din(regular);
						@include font-size(15);
						color: $grey;
						padding: 15px 20px;
					}
				}
			}
		}

		.row {
			[class^=span] {
				&.span6 {
					width: 50%;

					&:first-of-type {
						p,
						ul,
						li,
						table,
						tr,
						td,
						em,
						ol,
						h1,
						h2,
						h3,
						h4 {
							padding-left: 0;
						}

						.caption {
							p {
								padding-left: 20px;
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 868px) {
			hr {
				@include calc(width, '100% - 40px');
			}
		}

		@include max-tablet() {
			.row {
				[class^=span] {
					width: 100%;

					&.expand {
						width: 100%;
					}

					&.span6 {
						width: 100%;

						&:first-of-type {
							p,
							ul,
							li,
							table,
							tr,
							td,
							em,
							ol,
							h1,
							h2,
							h3,
							h4 {
								padding: 0 20px;
							}
						}
					}

					form {
						padding: 20px;
					}

					.caption {
						p {
							padding-left: 20px;
						}
					}
					//
					// p,
					// ul,
					// li,
					// a,
					// table,
					// tr,
					// td,
					// em,
					// ol,
					// h1,
					// h2,
					// h3,
					// h4 {
					// 	padding: 0 20px;
					// }
				}
			}
		}

		@include max-mobile() {

			.row {
				[class^=span] {


					.caption {
						.shape {
							@include transform(none);
						}

						p {
							padding: 10px 20px;
							@include font-size(17);
						}
					}
				}
			}

			p,
			ul,
			li,
			a,
			table,
			tr,
			td,
			em,
			ol {
				@include font-size(19);
			}

			.article-footer {

				div:not(.vacancy-links):last-of-type {
					text-align: left;
				}

				.vacancy-links {
					text-align: center;
				}

				.article-tags,
				.social-bar {
					width: 100%;
					text-align: left;

					p {
						padding-left: 0;
					}

					.social-links {
						float: none;
						display: inline-block;

						a {
							margin-right: 6px;

							&:last-of-type {
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		.span4, .span6 {
			img, .video-wrapper {
				padding-bottom: 20px;

				iframe {
					margin: 0;
					min-height: auto;
				}
			}
		}

		.span4 {
			img, .video-wrapper {
				padding-left: 3.333333px;
				padding-right: 3.333333px;

				@include max-tablet() {
					padding-left: 0;
					padding-right: 0;
				}

				iframe {
					margin: 0;
					min-height: auto;
				}
			}

			&:first-child {
				img, .video-wrapper {
					padding-left: 0;
					padding-right: 6.666666px;

					@include max-tablet() {
						padding-right: 0;
					}
				}
			}

			&:last-child {
				img, .video-wrapper {
					padding-left: 6.666666px;
					padding-right: 0;

					@include max-tablet() {
						padding-left: 0;
					}
				}
			}
		}

		.span6 {
			&:first-child {
				img, .video-wrapper {
					padding-right: 5px;

					@include max-tablet() {
						padding-right: 0;
					}
				}
			}

			&:last-child {
				img, .video-wrapper {
					padding-left: 5px;

					@include max-tablet() {
						padding-left: 0;
					}
				}
			}
		}

		.section-title {
			position: relative;
			margin: 40px 0 60px;

			@include max-tablet() {
				margin: 20px 0 40px;
			}

			.shape {
				position: absolute;
				width: calc(100% - 45px);
				height: 100%;
				transform: rotate(-1deg);
				background-color: var(--turquoise);
				margin-left: 10px;

				&:after {
					content: '';
					width: 45px;
					position: absolute;
					top: 0;
					right: -20px;
					height: 100%;
					transform: skew(-18deg);
					background-color: inherit;
				}
			}

			h2 {
				margin: 0;
				position: relative;
				@include Bree(bold-italic);
				@include font-size(24);
				color: $white;
				padding: 10px 0 12px 20px;
				transform: rotate(-0.5deg);
			}
		}

		.work-with-us-listing {

			.span4 {
				width: 100%;

				@media (min-width: $min-tablet) {
					width: 47%;
				}

				@media (min-width: 800px) {
					width: 30.83333%;
				}

				img {
					min-height: 233px;
					max-height: 233px;
					object-fit: cover;
					width: 100%;
				}
			}
		}

		.work-detail {
			img {
				min-height: 245px;
				max-height: 245px;
				object-fit: cover;
				min-width: 90%;

				@media (min-width: $min-desktop) {
					width: 300px;
					min-width: 300px;
				}
			}
		}
	}

	&.apply {
		background-color: var(--light-turq);

		.umbraco-forms-submitmessage {
			@include Bree(bold-italic);
			@include font-size(24);
			width: 100%;
			max-width: 850px;
			padding: 0 20px;
			margin: 0 auto;
			color: $white;
		}

		.standard-content-wrapper {
			padding: 0 1.25%;

			h2,
			h3,
			h4,
			h5 {
				color: $navy;

				body.high-contrast & {
					color: $white;
				}
			}

			p,
			b,
			strong,
			ul,
			ul li,
			li {
				color: $white;
			}

			a {
				color: black;
				text-decoration-color: black;
				font-weight: bold;
			}
		}

		.form-wrapper {
			padding: 0 20px;

			h4,
			fieldset,
			.umbraco-forms-navigation {
				max-width: 870px;
				margin: 0 auto;
			}

			fieldset {
				margin-bottom: 20px;
			}

			.umbraco-forms-container {
				h2 {
					padding: 0;
				}
			}

			h4 {
				@include Bree(bold-italic);
				@include font-size(24);
				padding: 0 1.25%;
				color: $navy;
				margin-bottom: 20px;

				body.high-contrast & {
					color: $white;
				}
			}

			legend,
			h2 {
				@include Bree(bold-italic);
				@include font-size(21);
				padding: 0 1.25%;
				color: $navy;
				margin-bottom: 20px;

				body.high-contrast & {
					color: $white;
				}
			}

			label {
				body.high-contrast & {
					color: $white;
				}
			}

			p {
				@include Din(regular);
				@include font-size(17);
				color: $navy;
			}
		}

		hr {
			background-color: $white;
			height: 3px;
			margin: 45px auto;
			@include calc(width, '100% - 40px');
		}
	}

	&.content-form {
		@include max-desktop() {
			margin: 0;
		}
	}
}
