.career-search {
	top: -50px;

	&:after {
		content: '';
		background-color: #efefef;
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	& > .row {
		@include flex();
		z-index: 5;

		[class^=span] {
			&:first-of-type {
				width: 71.6%;
			}

			&:last-of-type {
				margin-left: auto;
				background-color: $navy;
			}
		}
	}

	.career-search-form {
		padding: 20px 0 20px 20px;
		background-color: white;
		@include box-shadow(0, 1px, 2px, 0, grey);

		.row {
			display: block;

			[class^=span] {
				&:first-of-type {
					width: (percentage((6*$column+10*$margin)/$max-width));
				}

				&:last-of-type {
					padding: 0 0 0 20px;
					background-color: transparent;
				}
			}
		}

		h2 {
			color: var(--green);
			margin-bottom: 15px;
			@include Bree(bold-italic);
			@include font-size(24, 37);
		}

		form {
			h2 {
				color: var(--turquoise);
			}
		}

		.select-wrapper {
			float: left;
			width: calc(100% - 62px);

			select {
				background-color: white;
				color: $grey;
				border: 1px solid #d7d7d7;
				@include border-radius(3px 0 0 3px);
				height: 45px;
				@include Din(regular);
				@include font-size(17, 17);
				padding: 0 10px;

				option {
					&:disabled {
						color: rgba(116, 117, 129, 0.6);
					}
				}
			}
		}

		input[type="submit"] {
			display: block;
			float: left;
			width: 62px;
			height: 45px;
			background-color: var(--light-turq);
			color: white;
			text-align: center;
			line-height: 35px;
			@include Bree(bold-italic);
			@include font-size(18, 21);
			@include border-radius(0 3px 3px 0);
			@include transition(background-color .25s);

			&:hover,
			&:focus {
				background-color: rgba($light-turq, 0.8);

				body.high-contrast & {
					background-color: rgba($light-turq-high-contrast, 0.8);
				}
			}
		}

		.career-links {
			list-style: none;
			margin: 0;
			overflow: auto;
			padding-bottom: 14px;
			position: relative;
			top: -5px;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				padding: 0 0 1px 0;
				@include Bree(bold-italic);
				color: #2c4144;
				@include font-size(16, 20);

				a {
					color: inherit;
					text-decoration: none;
					display: block;
					position: relative;
					padding-bottom: 14px;
					padding-right: 14px;

					&:after {
						content: '';
						display: inline-block;
						width: 5px;
						height: 5px;
						border-right: 2px solid #2c4144;
						border-bottom: 2px solid #2c4144;
						@include transform(rotate(-45deg));
						margin-left: 7px;
						position: absolute;
						top: 9px;
						right: 4px;
					}

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}

				&:nth-of-type(even) {
					padding-left: 10px;
				}
			}
		}
	}

	.prospectus-cta {
			padding: 20px;
			text-align: center;
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));

	a.button {
		color: $navy;

		body.high-contrast & {
			color: $white;
		}

		&:hover,
		&:focus {
			color: var(--light-turq);
		}
	}

			h3 {
					color: white;
		@include Bree(bold-italic);
		@include font-size(20);
			}
	}

	@include max-mobile-nav() {
		.career-search-form {
			padding: 20px;
			.row {
				[class^=span] {
					width: 100%;
					margin: 0;

					&:first-of-type {
						width: 100%;
					}

					&:last-of-type {
						padding: 0;
						margin-top: 20px;
					}
				}
			}
		}
	}

	@include max-tablet() {

		& > .row {
			@include flex-direction(column);
			@include flex-wrap(wrap);
			[class^=span] {
				&:first-of-type,
				&:last-of-type {
					width: 100%;
				}
			}
		}

		.prospectus-cta {
			position: static;
			@include transform(none);
		}
	}

	@include max-mobile() {
		top: -50px;
		.career-search-form {

			.career-links {
				padding: 0;
				li {
					width: 100%;
					margin-bottom: 6px;

					&:nth-of-type(even) {
						padding-left: 0;
					}

				}
			}

			.select-wrapper {
				.select-arrow {
					bottom: 1px;

					&:before {
						@include font-size(25, 47);
					}
				}
			}
		}
	}
}

.home-promo {
	background-color: var(--yellow);
	min-height: 700px;

	@include max-tablet(){
		min-height: initial;
	}

	[class^=span] {
		position: absolute;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		padding: 50px 0;

		h2 {
			@include Bree(bold-italic);
			@include font-size(100);
			color: white;
			margin-bottom: 0;
			@include transform(translateX(-10px));

			@include max-tablet(){
				@include font-size(70);
			}
		}

		p {
			color: #2c4144;
			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 1.5rem;
			}

			body.high-contrast & {
				color: $white;
			}
		}

		.home-promo__welcome-message {
			padding-left: 5px;
			margin-bottom: 35px;

			img {
				width: auto;
			}
		}

		.home-promo__link {

			@include max-tablet(){
				text-align: center;
			}
		}


	}

	[class^=offset] {
		min-height: 700px;
		position: relative;
		overflow: hidden;

		@include max-tablet(){
			min-height: inherit;
		}

		img {
			display: block;
			max-width: initial;
			position: absolute;
			left: 0;
			height: 100%;
			width: auto;

			@include max-tablet(){
				height: auto;
				position: relative;
			}
		}
	}


	@include max-desktop() {
		padding-right: 0;
	}

	@include max-tablet() {
		padding-left: 0;

		[class^=span] {
			width: 100%;
			margin: 0;
			padding-right: 20px;
			padding-left: 20px;
			position: relative;
			@include transform(none);

			&.expand {
				width: 100%;
			}
		}

		[class^=offset] {
			width: 100%;
			margin: 0;
			clear: both;
			padding-left: 0;

			img {
				width: 100%;
				display: block;
			}
		}

	}

	@include max-mobile() {
		.row {
			[class^=span] {
				h2 {
					@include font-size(75);
				}

				img {
					max-width: 260px;
					display: block;
				}
			}

			[class*=offset] {
				margin-left: 0;
			}
		}
	}
}

.prospectus-area {
	background-color: $navy;
	color: white;
	padding: 100px 0;

	img {
		margin: 45px auto 0 auto;
		max-width: 380px;

		@include max-tablet() {
			max-width: initial;
		}
	}

	h3 {
		@include Bree(bold);
		@include font-size(30);
	}

	strong {
		display: block;
	}

	a.button {
		margin-top: 15px;
		color: $navy;

		body.high-contrast & {
			color: $white;
		}

		&.reveal-prospectus {
			&.active {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.button-green {
		color: $navy;

		&:hover,
		&:active {
			background-color: transparent;
			border: 2px solid #fff;
			color: #fff;
		}
	}

	.prospectus-reveal {
		padding-top: 100px;
		display: none;

		p {
			float: left;
			@include Din(bold);
			@include font-size(18, 44);
			margin: 0 1.25%;

			a {
				color: var(--light-turq);
			}
		}

		a.button {
			float: right;
			color: $navy;
			margin: 0;
			padding: 4px 17px 4px 16px;
			background-color: var(--light-turq);

			&:hover {
				background-color: transparent;
				border: 2px solid var(--light-turq);
				color: var(--light-turq);
			}
		}

		label {
			color: $white;
		}

		input[type="submit"] {
			float: right;
			border: 2px solid var(--light-turq);
			padding: 11px 21px 11px 20px;
			background-color: var(--light-turq);
			color: $navy;

			&:hover {
				background-color: transparent;
				border: 2px solid var(--light-turq);
				color: var(--light-turq);
			}
		}

		.button-green {
			color: $navy;

			&:hover,
			&:active {
				background-color: transparent;
				border: 2px solid #fff;
				color: #fff;
			}
		}

		.umbraco-forms-submitmessage {
			color: $white;
		}
	}

	@include max-desktop() {
		padding: 100px 20px 100px;
	}

	@include max-tablet() {
		padding: 50px 20px;

		.row {
			@include flex-direction(column);
		}

		[class^=span] {
			width: 100%;
			margin: 0;

			&.offset,
			&.expand {
				margin: 0;
				width: 100%;
			}
		}

		img {
			margin: 0 auto 30px;
			display: block;
			width: 350px;
			max-width: 100%;
		}
	}

	@include max-mobile() {
		.prospectus-reveal {
			padding-top: 0;

			a.button {
				margin-bottom: 12px;
			}

			p {
				display: block;
				width: 100%;
				float: none;
				margin-bottom: 35px;
			}
		}
		[class^=span] {
			width: 100% !important;
			margin: 0;

			&.offset,
			&.expand {
				margin: 0;
				width: 100% !important;
			}
		}
	}
}
