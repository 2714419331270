﻿.job-listing-template {
    .job-vacancy-filters {
        form {
            fieldset {
                width: 205px
            }
        }

        select {
            &:focus {
                outline: 0;
                background-color: $white;
                color: $grey;

                + .select-arrow {
                    background-color: $navy;

                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }

    .job-listing {
        .vacancy-category {
            @include font-size(14,17);
            @include Din(bold);
            color: $navy;
            margin-bottom: 10px;
        }

        h4.listing-title {
            @include font-size(19,29);
            margin-bottom: 10px;
        }
    }
}

.vacancy-links {
    a.button {
        &.jobs-listing-link {
            background-color: $navy;
            color: $white;

            &:hover {
                background-color: transparent;
                border: 2px solid $navy;
                color: $navy;
            }

            &:before {
                content: none !important;
            }

            &:visited, &:active {
                color: $white !important;
                background-color: $navy !important;
            }
        }
    }
}
