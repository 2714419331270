.event-listing {
	margin: 45px 0;

	h2 {
		@include Bree(bold-italic);
		color: $navy;
		@include font-size(24);
	}

	.span4 {
		margin-left: 0;
		width: 24%;
	}

	.span8 {
		margin-right: 0;
		float: right;
		width: 73%;
	}

	.event-filters {
		background-color: $white;
		@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
		padding: 30px;

		h3 {
			@include Bree(bold-italic);
			color: $navy;
			@include font-size(18);

			&:first-of-type {
				margin-top: 0;
			}
		}

		.month {
			margin: 30px 0 20px 0;
		}

		p {
			@include SourceSans(bold);
			@include font-size(18);
			color: $grey;
			margin-bottom: 5px;
		}

		ul {
			list-style-type: none;

			&.filter-tags {
				margin: 0;
				padding: 0;

				li {

					.checkbox-wrapper {
						label {
							@include SourceSans(semi-bold);
							color: $grey;
							@include font-size(15, 28);
							cursor: pointer;
							&:before {
								border: 1px solid $grey;
							}
							span {
								display: inline-block;

								&::first-letter {
									text-transform: uppercase;
								}

							}
						}
					}
				}
			}

			&.filter-month {
				li {
					@include SourceSans(semi-bold);
					color: $grey;
					@include font-size(15);
					padding-left: 20px;

					&.active {
						color: var(--turquoise);
						@include SourceSans(bold);
					}

					a {
						color: inherit;
						text-decoration: none;
					}
				}
			}
		}

		.reset {
			color: var(--orange);
			@include Bree(bold-italic);
			@include font-size(15);
			text-decoration: none;
			display: none;

			&:before {
				content: '';
				display: inline-block;
				position: relative;
				top: 3px;
				margin-right: 6px;
				width: 16px;
				height: 16px;
				background-image: url('../images/icons/orange-close.png');
				background-repeat: no-repeat;
			}
		}
	}

	.event-list {
		.event-item {
			background-color: $white;
			@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
			clear: both;
			margin-bottom: 25px;
			@include flex();

			.listing-image {
				width: 30%;
				max-width: 340px;
				overflow: hidden;
				float: left;
				position: relative;

				img {
					display: block;
					max-width: none;
					position: absolute;
					top: 50%;
				    left: 50%;
				    min-height: 100%;
				    min-width: 100%;
				    width: auto;
				    @include transform(translate(-50%, -50%));
				    @include transition(transform .25s ease-out);
				    will-change: transform;
				}

				&:hover,
			  &:focus {

		    	img {
		    		@include transform(translate(-50%, -50%) scale(1.05));
		    	}

		    	.overlay .shape {
		    			opacity: 0.9;
		    	}
		    }

				.overlay {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;

					.shape {
						width: 40%;
						position: absolute;
						height: 100%;
						background-color: var(--turquoise);
						opacity: 0.8;
						will-change: opacity;
						@include transition(opacity .25s);

						&:after {
							content: '';
							width: 70px;
							height: 100%;
							position: absolute;
							top: 0;
							right: -40px;
							background-color: inherit;
							backface-visibility: hidden;
							@include transform(skew(9deg));
						}
					}



					.date {
						width: 45%;
						float: left;
						position: relative;
						z-index: 11;
						top: 50%;
						padding-top: 10px;
						@include transform(translateY(-50%));

						span {
							display: block;
							width: 100%;
							color: $white;
							text-align: center;
							@include Din(bold);
							@include font-size(17);

							&:nth-of-type(1) {
								line-height: 0;
							}

							&:nth-of-type(2) {
								@include Bree(bold);
								@include font-size(80, 71);
							}
						}
					}
				}
			}

			.listing-content {
				float: left;
				width: 70%;
				padding: 11px 35px;

				.title {
					@include Bree(bold-italic);
					@include font-size(30);
					margin-bottom: 18px;

					a {
						text-decoration: none;
						color: inherit;

						&:hover,
						&:focus {
							text-decoration: underline;
						}
					}
				}

				.subtitle {
					@include Din(bold);
					color: $grey;
					@include font-size(15);
					margin-bottom: 10px;
				}

				span {
					display: block;
					width: 100%;
					margin-bottom: 5px;
					color: $grey;
					@include Din(regular);
					@include font-size(15);

					&:before {
						content: '';
						font-family: 'FontAwesome';
						width: 16px;
						height: 16px;
						@include font-size(16);
						margin-right: 6px;
						display: inline-block;
						text-align: center;
					}

					&.time {
						&:before {
							content: '\f017';
						}
					}

					&.location {
						&:before {
							content: '\f041';
						}
					}

					&.tags {
						&:before {
							content: '\f02d';
						}
					}

					&.price {
						&:before {
							content: '\f02b';
						}
					}
				}
			}

			&.green {
				.listing-image {
					.overlay {
						.shape {
							background-color: var(--turquoise);
						}
					}
				}

				.listing-content {
					.title,
					a.link {
						color: var(--turquoise);

						&:after {
							border-color: var(--turquoise);
						}
					}

					span {
						&:before {
							color: var(--turquoise);
						}
					}
				}
			}

			&.yellow {
				.listing-image {
					.overlay {
						.shape {
							background-color: var(--yellow);
						}
					}
				}

				.listing-content {
					.title,
					a.link {
						color: var(--yellow);

						&:after {
							border-color: var(--yellow);
						}
					}

					span {
						&:before {
							color: var(--yellow);
						}
					}
				}
			}

			&.orange {
				.listing-image {
					.overlay {
						.shape {
							background-color: var(--orange);
						}
					}
				}

				.listing-content {
					.title,
					a.link {
						color: var(--orange);

						&:after {
							border-color: var(--orange);
						}
					}

					span {
						&:before {
							color: var(--orange);
						}
					}
				}
			}
		}
	}

	@include small-desktop() {
		.span4,
		.span8 {
			width: 100% !important;
			margin: 0;
		}

		.event-filter-wrapper {
			background-color: $white;
			@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
			margin-bottom: 35px;

			h2 {
				padding: 15px 30px 15px;
				cursor: pointer;
				margin: 0;
				width: auto;
				display: inline-block;

				&:after {
					content: '\f107';
				    vertical-align: middle;
				    color: #747578;
				    font-family: "FontAwesome";
					@include font-size(25, 31);
					@include transition(all .3s ease);
				    position: absolute;
					font-style: normal;
					margin-left: 20px;
					margin-top: 4px;
				}

				&.active {
					&:after {
						@include rotate(-180deg);
					}
				}
			}

		}

		.event-filters {
			overflow: auto;
			background-color: transparent;
			-webkit-box-shadow: none;
		    -moz-box-shadow: none;
		    -ms-box-shadow: none;
		    box-shadow: none;
			display: none;

			.tags,
			.month {
				width: 100%;
				float: left;
				margin-top: 0;
			}

			.month {
				margin-top: 30px;
			}

			.tags {
				ul {
					padding-right: 10px;
					li {
						width: 33.33333%;
						float: left;
					}
				}
			}
		}
	}

	@include max-tablet() {
		.event-filters {
			margin-bottom: 35px;
			overflow: auto;
			.tags,
			.month {
				width: 100%;
				float: left;
				margin-top: 0;
			}

			.month {
				margin-top: 30px;
			}

			.tags {
				ul {
					padding-right: 10px;
					li {
						width: 50%;
						float: left;
					}
				}
			}
		}
		.event-list {
			.event-item {
				.listing-image {
					min-width: 240px;

					.overlay {
						.shape {
							&:after {
								@include transform(skew(7deg));
							}
						}
					}
				}
				.listing-content {
					padding: 15px 35px;
				}
			}
		}
	}

	@include max-mobile() {
		.event-filters {
			.tags,
			.month {
				width: 100%;
				float: none;
			}

			.tags {
				ul {
					padding-right: 10px;
					li {
						width: 100%;
						float: left;
					}
			}
		}

			.select-wrapper {
				.select-arrow {
					bottom: 16px;
				}
			}
		}

		.event-list {
			.event-item {

				.listing-image {
					width: 100%;
					max-width: 100%;
					max-height: 160px;

					img {
						position: static;
						@include transform(none);
					}

					&:hover {
						img {
							@include transform(none);
						}
					}
				}

				.listing-content {
					width: 100%;
				}
			}
		}

		.event-list {
			.event-item {
				@include flex-wrap(wrap);
				.listing-image {
					width: 100%;
					max-width: 100%;
					max-height: 160px;

					.listing-image {
						width: 100%;
						max-width: 100%;
						max-height: 160px;

						img {
							position: static;
							@include transform(none);
						}

						&:hover {
							img {
								@include transform(none);
							}
						}
					}
				}

				.listing-content {
					padding: 11px;
					width: 100%;
				}
			}
		}
	}
}
