.career-listing {
	clear: both;

	&>.row {
		background-color: $white;
		padding: 40px 60px;
		margin-bottom: 40px;
	}

	.intro {
		margin-bottom: 40px;

		h2 {
			@include Bree(bold-italic);
			@include font-size(30);
			color: var(--turquoise);
		}

		p {
			@include Din(regular);
			color: $navy;
			@include font-size(17);
		}
	}

	&.career-details {

			.intro {
				margin-bottom:0
			}
					
		}

	.career-search-form {

		a.reset {
			text-decoration: none;
			color: $navy;
			@include font-size(15);
			@include Din(demi);
			text-align: center;
			display: block;
			width: 100%;
			margin-top: 13px;

			i {
				display: inline-block;
				background-image: url('../images/icons/reset-ico.png');
				width: 17px;
				height: 17px;
				position: relative;
				top: 3px;
				margin-right: 4px;
			}
		}

		form {

			input[type="search"] {
				-webkit-box-sizing: border-box;
     			-moz-box-sizing: border-box;
          		box-sizing: border-box;
				width: 100%;
				border: 1px solid #d7d7d7;
				height: 45px;
				@include border-radius(5px 0 0 5px);
			}

			fieldset {
				float: left;
				@include calc(width, '100% - 130px');
			}

			.search-input {
				width: 128px;
				float: left;
				background-color: var(--brown);
				color: $white;
				// text-align: center;
				position: relative;
				height: 45px;
				padding: 0 20px;
				@include border-radius(0 5px 5px 0);
				@include transition(all .3s ease);

				&:hover {
					background-color: #8a7a4c;
				}

				i {
					display: block;
					color: $white;
					padding-top: 15px;
					margin-left: 5px;
				}

				input[type="submit"] {
					display: inline-block;
					background-color: transparent;
					@include Bree(bold-italic);
					@include font-size(18);
					color: $white;
					position: absolute;
					width: 100%;
					height: 100%;
					top: -1px;
					left: 0;
					text-indent: 17px;
				}
			}
		}
	}

	.career-alphabet {
		border-bottom: 2px solid #747578;
		text-align: center;
		position: static;
		@include transition(top .3s ease);
		ul {
			list-style-type: none;
			margin: 40px 0 0;
			padding: 0;
			display: inline-block;

			li {
				display: inline-block;
				padding: 0 10px 20px;

				&.hasCourse {
					a {
						color: $grey;
					}
				}

				&.top {
					background-image: url('../images/icons/career-listing-arrow.png');
					width: 19px;
					height: 19px;
					display: inline-block;
					cursor: pointer;
					background-repeat: no-repeat;
					background-position: 0 2px;
				}

				a {
					@include Bree(bold-italic);
					@include font-size(24);
					color: #b7b7b7;
					text-transform: uppercase;
					text-decoration: none;


				}
			}
		}

		&.fixed {
			position: fixed;
			top: 0;
			z-index: 25;
			background-color: $white;
			left: 50%;
			@include transform(translateX(-50%));
			max-width: 1200px;
			@include calc(width, '100% - 40px');
			ul {
				margin: 20px 0 0;
			}
		}
	}

	.career-list {

		&.career-details {

			.career-category .career-item .content {
				display:block;
			}
					
		}

		.career-category {

			&:nth-child(8n + 1) {
				.letter {
					color: var(--turquoise);
				}
				.career-item {
					.title {
						background-color: var(--turquoise);
					}
				}
			}

			&:nth-child(8n + 2) {
				.letter {
					color: var(--green);
				}
				.career-item {
					.title {
						background-color: var(--green);
					}
				}
			}

			&:nth-child(8n + 3) {
				.letter {
					color: var(--light-turq);
				}
				.career-item {
					.title {
						background-color: var(--light-turq);
					}
				}
			}

			&:nth-child(8n + 4) {
				.letter {
					color: var(--light-green);
				}
				.career-item {
					.title {
						background-color: var(--light-green);
					}
				}
			}

			&:nth-child(8n + 5) {
				.letter {
					color: var(--yellow);
				}
				.career-item {
					.title {
						background-color: var(--yellow);
					}
				}
			}

			&:nth-child(8n + 6) {
				.letter {
					color: var(--orange);
				}
				.career-item {
					.title {
						background-color: var(--orange);
					}
				}
			}

			&:nth-child(8n + 7) {
				.letter {
					color: $purple;
				}
				.career-item {
					.title {
						background-color: $purple;
					}
				}
			}

			&:nth-child(8n + 8) {
				.letter {
					color: $navy;
				}
				.career-item {
					.title {
						background-color: $navy;
					}
				}
			}

			.letter {
				@include Bree(bold-italic);
				color: var(--turquoise);
				@include font-size(48);
				margin: 40px 0 25px;
				display: block;
			}

			.career-item {
				margin-bottom: 10px;

				.title {
					width: 100%;
					background-color: var(--turquoise);
					color: $white;
					padding: 10px 20px;
					@include Din(bold);
					@include font-size(20);
					position: relative;
					cursor: pointer;
					@include transition(all .3s ease);
					text-decoration: none;
					display: block;

					span {
						float: right;
					    margin-right: 30px;
					    @include font-size(18);
					    font-family: "bree-serif", serif;
					    font-weight: 700;
					    font-style: italic;
					    display:none;
						
						@media only screen and (min-width: 768px) {
							display:block;							
						}
						
					}

					&:after {
						content: '\f0da';
						font-family: 'FontAwesome';
						color: $white;
						@include font-size(25);
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						right: 20px;
						@include transition(all .3s ease);
					}

					&.active {
						background-color: transparent;
						border-bottom: 3px solid var(--brown);
						color: var(--brown);

						&:after {
							color: var(--brown);
							@include transform(translateY(-50%) rotate(90deg));
						}
					}
				}

				.content {
					display: none;
					padding: 20px 15px;

					.content-intro {
						@include Din(light);
						@include font-size(18);
						color: #2c4144;

						p {
							@include Din(light);
							@include font-size(18);
							color: #2c4144;
						}
					}

					.row {

						[class^=span] {
							&:first-of-type {
								padding-right: 1.25%;
								margin-left:0;
							}
						}

						span {
							color: var(--brown);
							display: block;
							@include Bree(bold-italic);
							@include font-size(24);
							margin: 20px 0 15px;
						}

						p {
							@include Din(light);
							@include font-size(18);
							color: #2c4144;
						}

						ul {
							@include Din(light);
							@include font-size(18);
							color: #2c4144;
							margin-left: 3px;
							list-style-type: none;

							li {
								@include Din(light);
								@include font-size(18);
								color: #2c4144;

								&:before {
									content: '';
									background: $navy;
									display: block;
									float: left;
									width: 4px;
									height: 4px;
									margin-top: 11px;
									margin-right: 7px;
									@include border-radius(50%);
								}
							}
						}
					}

					.recommended-courses {
						span {
							color: var(--brown);
							display: block;
							@include Bree(bold-italic);
							@include font-size(24);
							margin: 20px 0 15px;
						}

						ul {
							list-style: none;

							li {
								width: 100%;
								border-bottom: 1px solid var(--brown);
								padding: 9px 0;

								@media (min-width: #{$max-mobile+1}) {
									display: flex;
									justify-content: space-between;
									align-items: flex-start;
								}

								a.course-title {
									text-decoration: none;
									@include Din(demi);
									color: #2c4144;
									@include font-size(18);
								}

								a.link {
									float: right;
									color: var(--brown);

									@media (min-width: #{$max-mobile+1}) {
										float: none;
										margin-left: 10px;
										flex-shrink: 0;
									}
	

									&:after {
										border-color: var(--brown);
									}
								}
							}
						}
					}

					.additional-content {
						padding-top: 0.2rem;
						h2, h3 {
							color: var(--brown);
							display: block;
							@include Bree(bold-italic);
							@include font-size(24);
							margin: 20px 0 15px;
						}

						p {
							@include Din(light);
							@include font-size(18);
							color: #2c4144;
						}

						ul {
							@include Din(light);
							@include font-size(18);
							color: #2c4144;
							margin-left: 3px;
							list-style-type: none;

							li {
								@include Din(light);
								@include font-size(18);
								color: #2c4144;

								&:before {
									content: '';
									background: $navy;
									display: block;
									float: left;
									width: 4px;
									height: 4px;
									margin-top: 11px;
									margin-right: 7px;
									@include border-radius(50%);
								}
							}
						}
					}
				}


			}
		}
	}

	@include max-desktop() {
		.career-alphabet {
			ul {
				li {
					padding: 0 6.3px 20px;
				}
			}
		}
	}

	@include max-mobile-nav() {
		.career-alphabet {
			overflow-x: scroll;

			ul {
				width: auto;
				overflow-y: hidden;
				white-space: nowrap;

				li {
					padding-bottom: 0;
				}
			}
		}
	}

	@include small-desktop() {

		.career-search-form {

			.row {
				[class^=span] {
					width: 100%;
					margin: 0;
					text-align: left;

					a.reset {
						text-align: left;
					}
				}
			}
		}

		.career-list {
			.career-category {
				.career-item {
					.content {
						.row {
							[class^=span] {
								width: 100%;
								margin: 0;
								padding: 0;
							}
						}
					}
				}
			}
		}
	}

	@include max-tablet() {
		& > .row {
			padding: 40px 20px;
		}
	}

	@include max-mobile() {

		.career-search-form {

			a.reset {
				margin-top: 0;
			}

			form {

				fieldset {
					@include calc(width, '100% - 88px');
				}

				.search-input {
					width: 88px;
					i {
						pointer-events: none;
						width: auto;
						text-align: center;
						position: relative;
						z-index: 1;
					}

					input[type="submit"] {
						color: var(--brown);
					}
				}
			}
		}

		.career-list {
			.career-category {
				.career-item {
					.title {
						@include font-size(17);
					}

					.content {
						.recommended-courses {
							ul {
								li {
									a.link {
										display: block;
										width: 100%;
										float: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
