.standard-content .profile-detail {

    @include max-tablet() {
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
    }

    > h2 {
    	margin-bottom: 10px;
    }

    .profile-image {
		float: right;
		margin: 12px 0 38px 38px;
		max-width: 50%;

		@include max-tablet() {
			float: none;
			margin: 0 0 14px 20px;
		}
    }

    .title {
    	@include Din(demi);
    	@include font-size(20, 30);
    	color: #5c5c5c;
    }
}