.search-results {
	margin-bottom: 50px;
	clear: both;

	.wrapper {
		background-color: $white;
		@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
		padding: 60px 75px;
		@extend .row;
	}

	.search-results-search {
		overflow: auto;
		form {

			input[type="search"] {
				-webkit-box-sizing: border-box;
     			-moz-box-sizing: border-box;
          		box-sizing: border-box;
				width: 100%;
				border: 1px solid #d7d7d7;
				height: 45px;
				@include border-radius(5px 0 0 5px);
			}

			fieldset {
				float: left;
				@include calc(width, '100% - 130px');
			}

			.search-input {
				width: 128px;
				float: left;
				background-color: var(--brown);
				color: $white;
				// text-align: center;
				position: relative;
				height: 45px;
				padding: 0 20px;
				@include border-radius(0 5px 5px 0);
				@include transition(all .3s ease);

				&:hover {
					background-color: #8a7a4c;
				}

				i {
					display: block;
					color: $white;
					padding-top: 15px;
					margin-left: 5px;
				}

				input[type="submit"] {
					display: inline-block;
					background-color: transparent;
					@include Bree(bold-italic);
					@include font-size(18);
					color: $white;
					position: absolute;
					width: 100%;
					height: 100%;
					top: -1px;
					left: 0;
					text-indent: 17px;
				}
			}
		}
	}

	.search-result-title {
		padding: 30px 0 30px;
		display: inline-block;
		width: 100%;

		h2 {
			@include Bree(bold-italic);
			@include font-size(28);
			color: $grey;
			margin-bottom: 7px;

			.result-keyword {
				color: var(--brown);

				&:before,
				&:after {
					content: '"';
					display: inline-block;
					color: var(--brown);
					@include Bree(bold-italic);
				}
			}
		}

		.result-count {
			p {
				@include Din(demi);
				@include font-size(18);
				color: $grey;

				span {
					color: var(--brown);
					display: inline-block;
					padding: 0 2px;
				}
			}
		}
	}

	.search-result-list {

		.no-results {
			p {
				@include Din(light);
				@include font-size(18);
				color: $navy;
			}

			a {
				@include Din(demi);
				color: var(--turquoise);
				@include font-size(18);
				text-decoration-color: var(--turquoise);

				&[href^="tel:"] {
					text-decoration: none;
				}
			}
		}

		.search-result-item {
			padding: 25px 0;
			border-bottom: 3px solid var(--light-turq);

			&:first-of-type() {
				border-top: 3px solid var(--light-turq);
			}

			&:hover {
				span {
					text-decoration: underline;
					text-decoration-color: var(--brown);
				}
			}

			a {
				text-decoration: none;
			}

			span {
				@include Bree(bold);
				color: var(--brown);
				@include font-size(20);
				margin-bottom: 20px;
				display: block;
				width: 100%;
			}

			p {
				@include Din(light);
				color: $navy;
				@include font-size(16);
				margin: 0;
			}
		}

		.pagination {
			margin-bottom: 0;

			ul {
				li {
					&.next,
					&.prev {
						background-color: var(--turquoise);
						color: $white;
					}
				}
			}
		}
	}

	@include small-desktop() {

		.wrapper {
			padding: 30px 25px 60px;
		}
	}

	@include max-tablet() {
		.search-result-title {
			[class^=span] {
				&.expand {
					width: 100%;
					margin: 0;
				}
			}
		}
	}

	@include max-mobile() {

		.search-results-search {
			form {

				fieldset {
					@include calc(width, '100% - 80px');
				}

				.search-input {
					width: 80px;
					i {
						text-align: center;
						position: relative;
						z-index: 1;
						@include font-size(20);
						padding-top: 13px;
					}

					input[type="submit"] {
						text-indent: 0;
						color: var(--brown);
					}
				}
			}
		}

		.search-result-title {

			[class^=span] {
				.select-wrapper {
					.select-arrow {
						bottom: 16px;
					}
				}
			}

			h2 {
				span {
					display: block;
					width: 100%;
				}
			}
		}
	}
}
