:root {
  body.high-contrast {
    --turquoise: #022F3A;
    --orange: #5D2F26;
    --green: #21702B;
    --light-green: #5d9957;
    --light-turq: #157C6C;
    --brown: #715E21;
    --yellow: #8E630A;
  }
}

// Search for "body.high-contrast &" for selector specific changes 