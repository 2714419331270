.standard-content .profile-listing {
	@include flex();
	@include flex-wrap(wrap);

	[class^=span] {
		padding-top: 28px;
	    margin-bottom: 28px;

	    @include max-tablet() {
			margin-left: 0;
			margin-right: 0;
			padding-left: 20px;
			padding-right: 20px;
	    }

	    &:last-child {
	    	margin-bottom: 0;
	    }
	}

	.image-wrap {
		display: block;
		position: relative;
		overflow: hidden;

		.caption {
		    position: absolute;
			bottom: 0;

    		.shape {
				-webkit-transform: rotate(-0.5deg);
				-ms-transform: rotate(-0.5deg);
				transform: rotate(-0.5deg);

				&:after {
					-webkit-transform: skew(-12deg);
					-ms-transform: skew(-12deg);
					transform: skew(-12deg);
				}
    		}

    		p {
				margin: 0;
				position: relative;
				@include font-size(21, 25);
				@include Bree(bold-italic);
				padding: 10px 0 12px 20px;
				-webkit-transform: rotate(0.5deg);
				-ms-transform: rotate(0.5deg);
				transform: rotate(0.5deg);
    		}
		}
	}

	.title {
		@include Din(demi);
		color: #5c5c5c;
		@include font-size(15, 20);
		padding: 18px 12px 0;
	}

	.text {
		@include Din(light);
		color: #2c4144;
		@include font-size(15, 20);
		padding: 0 12px;
	    margin-bottom: 4px;
	}

	.read-more {
		@include Bree(bold-italic);
		color: var(--turquoise);
		@include font-size(16, 38);
		text-decoration: none;
		margin-left: 12px;

		&:after {
			content: '';
			display: inline-block;
			width: 6px;
			height: 6px;
			border-right: 2px solid var(--turquoise);
			border-bottom: 2px solid var(--turquoise);
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
			position: relative;
			top: -1px;
			margin-left: 5px;
		}
	}
}