/*** Hero Banner Styles ***/

.hero {

	&.carousel {
		padding: 0;

		.hero-slider {
			width: 100%;

			.hero-slide {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				overflow: hidden;
				position: relative;
				min-height: 540px;
				height: 60vh;

				@media (min-width: 768px) {
					display: flex;
					justify-content: center;
				}

				@media (min-width: 768px) and (max-width: 1250px) {
					img {
						height: 100%;
					}
				}

				.slider-content {
					body.high-contrast & {
						.button-navy,
						.button-purple {
							&:hover,
							&:focus {
								color: $white;
								border-color: $white;
								background-color: transparent;
							}
						}
					}
				}

				&:nth-of-type(even) {
					.row {
						[class^=span] {
							&:before,
							&:after {

								@supports (display: grid) {
									background-color: var(--turquoise);
								}
							}
						}

						.slider-content {
							background-color: var(--turquoise);

							body.high-contrast & {
								.button-navy {
									background-color: var(--light-turq);

									&:hover,
									&:focus {
										color: $white;
										border-color: $white;
										background-color: transparent;
									}
								}
							}

							@include max-tablet() {
								background-color: rgba($turquoise, 0.7);

								body.high-contrast & {
									background-color: rgba($turquoise-high-contrast, 0.7);
								}
							}
						}
					}
				}

				video {
					position: absolute;
					min-width: 100%;
					min-height: 100%;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
					object-fit: cover;
				}

				.row {
					position: absolute;
					bottom: 0;
					left: 0;
					margin: 0 auto;
					right: 0;
					padding: 0;
					@include flex();
					width: 100%;
					padding: 0 10px;

					[class^=span] {
						&:before {
							position: absolute;
							top: -13px;
							left: -4px;
							width: 13px;
							height: 100%;
							background-color: var(--green);
							@include transform(skew(6deg));
							z-index: 60;

							@supports (display: grid) {
								content: '';
							}
						}

						&:after {
							position: absolute;
							top: 0px;
							right: -3px;
							width: 12px;
							height: 100%;
							background-color: var(--green);
							@include transform(skew(-6deg));
							z-index: 60;

							@supports (display: grid) {
								content: '';
							}
						}
					}

					.slider-image {
						display: none;
					}

					.slider-content {
						position: relative;
						z-index: 10;
						padding: 10px 25px 60px 25px;
						background-color: var(--green);
						display: flex;
						justify-content: space-between;
						flex-flow: row nowrap;
						min-height: 191px;

						@include max-tablet() {
							background-color: rgba($green, 0.7);

							body.high-contrast & {
								background-color: rgba($green-high-contrast, 0.7);
							}
						}

						@supports (display: grid) {
							padding: 0 25px 60px 25px;
							display: grid;
							grid-template-columns: 1fr min-content;
							grid-gap: 20px;
						}

						&:before {
							position: absolute;
							top: -3px;
							left: -1px;
							width: 100%;
							height: 15px;
							background-color: inherit;
							@include transform(rotate(1deg));
							z-index: -1;

							@supports (display: grid) {
								content: '';
							}
						}

						.slider-content-inner {
							padding-right: 20px;
							flex: 0;
							flex-basis: auto;

							@supports (display: grid) {
								padding-right: 0;
							}

							h1 {
								@include Bree(bold-italic);
								color: white;
								@include font-size(30, 38);
								margin-bottom: 5px;
								width: 100%;
							}

							p {
								@include font-size(17, 25);
								@include Din(demi);
								color: white;
								width: 100%;

								&:last-of-type {
									margin: 0;
								}
							}
						}


						.button-row {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							flex-flow: row nowrap;
							position: relative;
							flex: 0 1 50%;

							a {
								display: block;
								height: 44px;
								margin-right: 15px;

								&:last-of-type {
									margin-right: 0;
								}
								// & + .spacer {
								// 	padding-bottom: 23px;
								// }
							}

							.button {
								white-space: nowrap;
							}
						}
					}
				}
			}
		}

		.slider-nav {
			@include flex();
			height: 0;
			bottom: 230px;
			padding: 0 35px;

			.slider-nav-inner {
				@include flex();
				@include flex-justify(flex-end);
				flex-grow: 1;
			}

			.slider-arrow {
				@include inline-flex();
				@include flex-align(center);
				@include flex-justify(center);
				width: 24px;
				height: 24px;
				background-color: var(--light-turq);
				pointer-events: auto;
				cursor: pointer;
				margin: 9px 0 9px 14px;
				padding: 0;

				img {
					width: 20px;
					height: 20px;
				}

				&.prev {
					margin: 9px 14px 9px 0;

					img {
						transform: scaleX(-1);
					}
				}
			}

			.slick-dots {
				@include flex();
				@include flex-align(flex-start);
				position: static;
				width: auto;
				margin: 0;

				li {
					width: 42px;
					height: 42px;
					margin: 0;
					@include flex();
					@include flex-align(center);
					@include flex-justify(center);

					&:last-of-type {
						margin: 0;
					}

					&.slick-active {
						button {
							background-color: $navy;
						}
					}

					button {
						background-color: $white;
						width: 15px;
						height: 15px;
					}
				}
			}

			.slider-control {
				@include inline-flex();
				@include flex-align(center);
				@include flex-justify(center);
				width: 24px;
				height: 24px;
				background-color: $navy;
				pointer-events: auto;
				cursor: pointer;
				margin: 9px 0 9px 14px;
				padding: 0;

				&.hidden {
					display: none;
				}

				&.play img {
					width: 12px;
					height: 14px;
				}

				&.pause img {
					width: 8px;
					height: 14px;
				}
			}
		}

		@include max-desktop() {
			.hero-slider {
				.hero-slide {
					.row {
						padding: 0 20px;

						.slider-content {
							padding: 0 15px 60px 15px;

							.button-row {

								a.button {
									padding: 11px 12px 13px 11px;
								}
							}
						}
					}
				}
			}

			.slider-dots {
				.slick-dots {
					right: 20px;
				}
			}
		}

		@include small-desktop() {
			.hero-slider {
				.hero-slide {
					background-position: center center;

					.row {

						[class^=span] {
							&:before {
								content: none;
							}

							&:after {
								content: none;
							}
						}

						.slider-content {
							align-items: center;

							&:before {
								top: -7px;
							}

							.slider-content-inner {
								h1 {
									@include font-size(25);
								}
							}

							.button-row {
								padding: 20px 0 10px;
								flex-flow: column wrap;
								align-items: flex-end;

								a.button {
									@include font-size(16);
									padding: 9px 12px 13px 11px;

									&:first-of-type {
										margin-right: 0;
										margin-bottom: 6px;
									}

									&:last-of-type {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}

			.slider-dots {
				.slick-dots {
					bottom: 180px;
				}
			}
		}
		//iOS fix for VH
		@media all and (device-width: 768px) and (orientation:portrait) {
			.hero-slider {
				.hero-slide {
					height: 540px;
				}
			}
		}

		@include max-tablet() {
			.hero-slider {
				.hero-slide {
					background-position: center top;
					max-height: none;
					height: auto;
					min-height: 0;

					.row {
						position: relative;
						padding: 0;

						[class^=span] {
							&:before,
							&:after {
								display: none;
							}
						}

						.slider-image {
							display: block;
							height: 60vh;
							min-height: 350px;
							width: 100%;
							position: relative;

							@supports (object-fit: cover) {
								height: 30vh;
								min-height: auto;
							}

							img {
								width: 100%;
								min-height: 100%;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);

								@supports (object-fit: cover) {
									height: 100%;
									object-fit: cover;
									min-height: auto;
								}
							}
						}

						.slider-content {
							@include flex-align(flex-start);
							height: 300px;
							padding: 15px 15px 60px 15px;

							@supports (display: grid) {
								//grid-template-columns: 1fr;
								//grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
								grid-gap: 40px;
							}

							&:before {
								display: none;
							}

							.button-row {
								//justify-content: flex-start;
								//flex-flow: row nowrap;
								padding: 10px 0;

								a.button {
									// &:first-of-type {
									// 	margin-right: 8px;
									// 	margin-bottom: 0;
									// }
								}
							}
						}
					}
				}
			}

			.slider-nav {
				position: absolute;
				bottom: 300px;
				height: auto;
				width: 100%;
				padding: 10px;
				background-color: $navy;

				&::after {
					content: none;
				}

				.slider-nav-inner {
					@include flex-justify(space-between);
				}

				.slider-arrow {
					margin: 0;

					&.prev {
						margin: 0;
					}
				}

				.slick-dots li {
					height: 24px;

					button {
						margin-bottom: 0;
					}

					&.slick-active button {
						background-color: var(--light-turq);
					}
				}

				.slider-control {
					margin: 0 0 0 15px;
				}
			}
		}

		@include max-mobile() {
			.hero-slider {
				.hero-slide {
					background-position: center center;

					.row {
						position: relative;
						height: 100%;

						.slider-image {
							height: auto;

							@supports (object-fit: cover) {
								height: 30vh;
								min-height: auto;
							}

							img {
								min-height: auto;
								position: relative;
								transform: none;
								top: 0;

								@supports (object-fit: cover) {
									position: absolute;
								}
							}
						}

						.slider-content {
							padding: 15px 15px 55px 15px;
							width: 100%;
							flex-flow: column nowrap;
							align-items: flex-start;
							height: 220px;

							@supports (display: grid) {
								grid-template-columns: 1fr;
								grid-template-rows: repeat(2, min-content);
								grid-gap: 10px;
							}

							.slider-content-inner {
								margin-bottom: 10px;
								flex: initial;
								width: 100%;

								@supports (display: grid) {
									margin-bottom: 0;
								}
							}

							h1 {
								@include font-size(24);
								margin-bottom: 0;
							}

							p {
								display: none;
							}

							.button-row {
								flex-flow: row wrap;
								justify-content: flex-start;
								align-items: flex-start;
								padding: 0;
								flex: auto;

								a.button {
									&:first-of-type {
										margin: 0 10px 10px 0;
									}
								}
							}
						}
					}
				}
			}

			.slider-nav {
				bottom: 220px;
			}
		}
	}

	&.standard-hero {
		// min-height: 290px;
		margin-bottom: 40px;

		.hero-content {
			.breadcrumbs {
				.shape {
					background-color: $light-green;
					border-color: $light-green;

					body.high-contrast & {
						background-color: var(--green);
						border-color: var(--green);
					}
				}
			}

			.title {
				.shape {
					background-color: var(--turquoise);
					border-color: var(--turquoise);
				}
			}
		}

		@include max-mobile() {
			[class^=span] {
				width: 100%;
			}

			.hero-content {
				.title,
				.breadcrumbs {
					display: block;
					width: 70%;

					.the-title {
						h1 {
							@include font-size(32);
						}
					}
				}
			}
		}
	}

	&.hasBackground {
		min-height: 400px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		.hero-content {
			padding-top: 40px;
		}



		&.top-tier {
			min-height: 550px;

			.hero-content {
				padding-top: 110px;
			}
		}

		&.tier-two {
			.hero-content {
				padding-top: 50px;
			}
		}

		& + .search-results,
		& + .contact-intro {
			margin-top: -60px;
		}

		& + .event {
			margin-top: -40px;
		}

		& + .career-listing {
			& > .row {
				margin-top: -100px;
			}
		}

		& + .apply {
			margin-top: -120px;
		}

		@include max-mobile() {
			&.top-tier {
				min-height: 350px;

				.hero-content {
					padding-top: 40px;
				}
			}
		}
	}

	&.error-hero {
		background-color: var(--orange);
		text-align: center;
		min-height: 450px;

		.hero-content {
			display: inline-block;
			padding: 65px 0 100px;
		}

		@include max-mobile() {
			min-height: auto;

			svg,
			object,
			.shape {
				display: block !important;
				width: 100% !important;
				height: 100%;
			}
		}
	}

	.hero-content {
		// padding-top: 40px;

		.shape {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			@include rotate(-3deg);
			opacity: 0.8;

			&:before {
				content: '';
				position: absolute;
				width: 750%;
				height: 100%;
				left: -749%;
				background-color: inherit;
			}

			&:after {
				content: '';
				width: 150px;
				height: 100%;
				position: absolute;
				top: 0;
				right: -40px;
				background-color: inherit;
				backface-visibility: hidden;
				@include transform(skew(-20deg));
			}
		}

		.breadcrumbs {
			position: relative;
			min-height: 40px;
			display: inline-block;
			padding-right: 20px;
			/*z-index: 11;*/

			@media (min-width: $min-tablet) {
				bottom: -0.4rem;

				&.small-title {
					bottom: -0.5rem;
				}

				&.medium-title {
					bottom: -0.85rem;
				}

				&.large-title {
					bottom: -0.25rem;
				}
			}

			.breadcrumb-content {
				position: relative;
				z-index: 5;
				min-height: 40px;
				padding-top: 8px;
				@include transform(rotate(-3deg));
			}

			a,
			p {
				display: inline-block;
				@include Din(bold-italic);
				color: white;
				@include font-size(16);
				text-decoration: none;
				margin: 0;
				letter-spacing: 0.2px;

				&:hover {
					text-decoration: underline;
				}
			}

			a:not(:last-of-type) {
				&:after {
					content: '';
					display: inline-block;
					width: 4px;
					height: 4px;
					margin: 0 4px 0 10px;
					background-color: $white;
					@include border-radius(50%);
					position: relative;
					top: -2px;
				}
			}
		}

		.title {
			position: relative;
			/*margin: 32px 0;*/
			display: table;
			// padding-right: 40px;

			.the-title {
				position: relative;
				z-index: 5;
				padding: 14px 0 31px 0;
				@include transform(rotate(-3deg));

				h1 {
					margin: 0;
					@include Bree(bold-italic);
					@include font-size(52, 50);
					color: $white;
				}
			}
		}

		@include small-desktop() {
			.shape {
				&:after {
					width: 102px;
					right: -40px;
				}
			}
		}

		@include max-tablet() {
			.shape {
				&:after {
					right: -50px;
				}
			}
		}

		@include max-mobile() {
			.breadcrumbs {
				padding-right: 0;
			}

			.title {
				.the-title {
					padding: 14px 0 15px;
				}
			}
		}
	}
}