
#CybotCookiebotDialogHeader {
    display: none !important;
}

#CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogBodyButtonDecline  {
    border: none!important;
    font-family: "bree-serif", serif !important;
    font-style: italic;
}


#CybotCookiebotDialogBodyButtonAccept {
    background-color: var(--light-turq) !important;
    color: #022F3A !important;
}

#CybotCookiebotDialogBodyButtonDecline {
    background-color: #022F3A !important;
    color: $white !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
    border-bottom: 1px solid #022F3A !important;
    color: #022F3A !important;
}

.CybotCookiebotDialogNavItemLink:hover {
    color: #022F3A !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    color: #022F3A !important;
}