/*** Site Footer Styles ***/

footer {
	background-color: $white;
	padding: 60px 0;

	.footer-logo {
		img {
			margin-bottom: 25px;
		}
	}

	.footer-address {
		@include font-size(16);
		@include Din(regular);
		color: $grey;
	}

	h3 {
		color: $grey;
		@include font-size(17);
		@include Bree(bold);
		margin-bottom: 25px;
	}

	.footer-quick-links {
		list-style-type: none;

		li {
			@include font-size(16);
			@include Din(regular);
			color: $grey;
			float: left;
			width: 50%;
			vertical-align: top;

			&:last-of-type,
			&:nth-of-type(5n) {
				padding-bottom: 0;
			}

			a {
				color: inherit;
				text-decoration: none;
				padding-bottom: 20px;
				display: block;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	.footer-contact {
		@include font-size(16);
		@include Din(bold);
		color: $grey;
		margin-bottom: 30px;
		min-width: 145px;

		span {
			padding-bottom: 20px;
			display: block;
		}

		a {
			@include font-size(16);
			@include Din(regular);
			color: $grey;
			text-decoration: none;
		}
	}

	.footer-social {
		a {
			color: $navy;
			@include font-size(27);
			text-decoration: none;
			margin-right: 18px;
			margin-bottom: 18px;
			display: inline-block;

			@include max-desktop() {
				margin-right: 22px;
			}

			&:hover,
			&:focus {

				i {
					@include translatey(-5px);
				}

			}

			&:last-of-type {
				margin-right: 0;
			}

			i {
				color: $navy;
				@include font-size(27);
				@include transition(transform .25s);
				will-change: transform;
			}
		}
	}

	.accreditations {
		min-width: 280px;
		img {
			margin-right: 5px;
			&:nth-of-type(3n) {
				margin-right: 0;
			}
		}
	}

	@include max-desktop() {
		padding: 60px 20px;
		.row {
			[class^=span] {
				&.offset1,
				&.expand {
					margin: 0 1.25%;
				}
			}
		}
	}

	@include max-mobile-nav() {
		.row {
			[class^=span] {
				width: (percentage((3*$column+4*$margin)/$max-width));

				&.expand {
					width: (percentage((3*$column+4*$margin)/$max-width));
				}
			}
		}

		.footer-quick-links {
			li {
				width: 100%;

				&:nth-of-type(5n) {
					padding-bottom: 10px;
				}
			}
		}

		.footer-contact {
			span {
				display: block;
				width: 100%;
			}
		}

		.accreditations {
			img {
				&:nth-of-type(1) {
					display: block;
					margin-bottom: 12px;
				}
			}
		}
	}

	// @include small-desktop() {
	// 	.row {
	// 		[class^=span] {
	// 			width: 56%;
	// 			margin: 0;
    //
	// 			&.offset1,
	// 			&.expand {
	// 				margin: 0;
	// 			}
    //
	// 			&:first-of-type {
	// 				width: auto;
	// 				display: inline-block;
	// 				margin: 0 0 40px;
    //
	// 				.footer-logo {
	// 					width: 100%;
	// 					img {
	// 						display: block;
	// 					}
	// 				}
    //
	// 				.footer-address {
	// 					display: inline-block;
	// 				}
	// 			}
    //
	// 			&:nth-child(2) {
	// 				margin-left: 40px;
	// 			}
    //
	// 			&:nth-child(3) {
	// 				clear: both;
	// 			}
    //
	// 			.footer-contact {
	// 				width: 35%;
	// 				float: left;
	// 				min-width: 196px;
	// 			}
    //
	// 			.footer-social {
	// 				margin-top: 0;
	// 				width: 50%;
	// 				float: left;
	// 				padding-left: 40px;
	// 			}
	// 		}
	// 	}
	// }

	@include max-tablet() {
		.row {
			@include flex();
			@include flex-wrap(wrap);
			[class^=span] {
				width: 50%;
				margin: 0 0 35px;

				&.expand,
				&.offset1 {
					width: 50%;
					margin: 0 0 35px;
				}

				.footer-quick-links {
					li {
						width: 50%;
					}
				}

				.footer-social,
				.footer-contact {
					float: none;
					clear: both;
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	@include max-mobile() {
		.row {
			[class^=span] {
				width: 100%;
				margin: 0 0 25px;

				&.expand,
				&.offset1 {
					width: 100%;
					margin: 0 0 25px;
				}

				.footer-quick-links {
					li {
						width: 100%;
					}
				}

				.accreditations {
					img {
						&:nth-of-type(1) {
							display: inline-block;
							margin-bottom: 0;
						}
					}
				}

				.footer-contact {
					span {
						display: block;
						width: 100%;

						&:first-of-type {
							margin-bottom: 4px;
						}
					}
				}
			}
		}
	}
}

.footer-accessibility-heading {
	clear: both;
	margin: 0;
	padding: 30px 0 15px;
}

.footer-accessibility-text {
	color: $grey;
	@include Din(regular);
	@include font-size(16);
	cursor: pointer;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	img {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		top: -1px;
		margin-right: 5px;
		width: 20px;
		height: 13px;
	}
}