.event {
	margin-top: 40px;
	margin-bottom: 50px;
	clear: both;

	.span4 {
		margin-left: 0;
		width: 24%;
	}

	.span8 {
		margin-right: 0;
		float: right;
		width: 73%;
	}

	.event-details {
		background-color: $white;
		@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
		padding: 40px 25px;

		hr {
			background-color: var(--yellow);
			height: 3px;
			width: 100%;
			margin: 0 0 30px;
			border: 0;

			&:last-of-type {
				margin-top: 25px;
			}
		}

		.date {
			span {
				display: block;
				width: 100%;
				color: $grey;
				@include Din(bold);
				@include font-size(18);

				&:nth-of-type(2) {
					color: var(--orange);
					@include font-size(86, 63);
					@include Bree(bold);

					em {
						@include font-size(30, 63);
						font-style: normal;
					}
				}

				&:nth-of-type(3) {
					margin-top: -6px;
				}
			}
		}

		.event-info {
			margin-top: 30px;

			span {
				width: 100%;
				display: block;
				@include font-size(17);
				@include Din(regular);
				color: $grey;
				padding-left: 25px;
				position: relative;
				margin-bottom: 9px;

				a {
					font-family: inherit;
					color: inherit;
					text-decoration: none;
				}

				&:before {
					content: '';
					font-family: 'FontAwesome';
					position: absolute;
					top: 0;
					left: 0;
					width: 16px;
					height: 16px;
					margin-right: 6px;
					text-align: center;
					padding-right: 8px;
					color: var(--orange);
				}

				&.time {
					&:before {
						content: '\f017';
					}
				}

				&.location {
					&:before {
						content: '\f041';
					}
				}

				&.tags {
					&:before {
						content: '\f02d';
					}
				}

				&.price {
					&:before {
						content: '\f02b';
					}
				}

				&.course {
					&:before {
						content: '\f19d';
					}
				}
			}
		}

		.event-tel {
			p {
				@include Bree(bold-italic);
				color: var(--orange);
				@include font-size(15);
				margin-bottom: 8px;
			}

			span {
				@include Bree(bold-italic);
				@include font-size(30);
				color: var(--orange);
				margin-bottom: 15px;
				display: block;
			}
		}

		a.button {
			@include font-size(15);
		}
	}

	.event-content {
		background-color: $white;
		@include box-shadow(0, 1px, 4px, 0, #d7d7d7);
		padding: 40px;
		margin-top: 0;
		margin-bottom: 0;

		p,
		ul,
		li,
		a,
		table,
		tr,
		td,
		em,
		ol,
		h2 {
			padding: 0;
		}

		hr {
			background-color: var(--yellow);
			height: 3px;
			width: 100%;
			margin: 0 0 30px;
			border: 0;
		}

		.article-footer {

			& > div {
				text-align: left;

				&:last-of-type {
					text-align: left;
				}

				&:first-of-type {
					text-align: right;
				}
			}

			.article-tags,
			.social-bar {
				padding: 0;
			}

			.article-tags {
				width: 100%;
			}

			.social-bar {
				width: 49.5%;
				display: inline-block;
				float: none;
				text-align: right;

				p {
					padding-right: 5px;
				}
			}
		}

		.intro {
			@include Din(bold);
			@include font-size(15);
			color: $grey;
			margin-bottom: 2px;
			width: 49.5%;
			display: inline-block;

			span {
				color: var(--brown);
			}
		}
	}

	.register-interest {
		background-color: var(--orange);
		padding: 40px 30px;
		display: inline-block;
		width: 100%;
		margin-top: 32px;

		h3,
		h4 {
			color: $white;
			@include Bree(bold-italic);
			@include font-size(24);
			margin-bottom: 20px;
			margin: 0 1.25%;
		}

		p {
			color: white;
		}

		form {
			fieldset {
				width: 100%;
				float: left;
				margin-bottom: 15px;

				&:nth-of-type(even) {
					float: right;
				}
			}

			input[type="submit"] {
				float: right;

				&.prev {
					float: left;
				}
			}
		}

		.button {
			background-color: $navy;
			color: white;
			border: 2px solid $navy;
			float: right;

			&:hover,
			&:focus {
				background-color: transparent;
				color: $navy;
			}

			&.submitted {
				background-color: transparent;
				border: 2px solid $navy;
				color: $navy;
			}

			body.high-contrast & {
				background-color: $white;
				color: $navy;
				border: 2px solid $white;

				&:hover,
				&:focus {
					background-color: transparent;
					color: $white;
				}

				&.submitted {
					background-color: transparent;
					border: 2px solid $white;
					color: $white;
				}
			}
		}

		.umbraco-forms-submitmessage {
			color: $navy;

			body.high-contrast & {
				color: $white;
			}
		}

		label,
		legend,
		.field-validation-error {
			body.high-contrast & {
				color: $white;
			}
		}
	}

	@include small-desktop() {
		.span4,
		.span8 {
			width: 100% !important;
			margin: 0;
			float: none;
		}

		.span4 {
			margin-bottom: 30px;
		}

		.event-content {
			padding: 30px 25px;
		}

		.event-details {
			@include flex();
			@include flex-wrap(wrap);
			@include flex-align(center);

			.date {
				width: 120px;
				float: left;
				margin-bottom: 30px;
			}

			.event-info {
				@include calc(width, '100% - 320px');
				float: left;
				padding-left: 30px;
				margin-top: 0;

				& + hr {
					@include flex-order(5);
					margin: 0;
				}

				span {
					width: 50%;
					display: inline-block;
					float: left;

					&.time,
					&.location {
					}

					&.tags,
					&.price,
					&.course {
						// float: right;
					}
				}
			}

			a.button {
				height: 49px;
			}
		}
	}

	@include max-tablet() {
		.event-content {
			.article-footer {
				p.intro {
					width: 100%;
				}

				.article-tags,
				.social-bar,
				& > div:last-of-type {
					width: 100%;
					text-align: left;
					padding: 10px 0 0;
					float: none;
					clear: both;

					.addthis_inline_share_toolbox {
						display: inline-block;
						float: none;
						width: auto;
						padding: 0;
					}
				}

				.social-bar {
					p {
						float: left;
					}
				}
			}
		}

		.event-details {
			display: block;

			.date {
				width: 100%;
			}

			.event-info {
				padding-left: 0;
				float: none;
				width: 100%;
				clear: both;

				& + hr {
					margin: 30px 0;
					clear: both;
				}

				span {
					width: 100%;
					display: block;
					float: none;
				}
			}

			a.button {
				height: auto;
			}
		}
	}

	@include max-mobile() {
		.register-interest {
			padding: 15px;

			form {
				fieldset {
					width: 100%;
					float: none;
					margin-bottom: 0;

					&:nth-of-type(even) {
						width: 100%;
						float: none;
					}
				}
			}
		}
	}
}
