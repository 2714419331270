/* Transition and animation mixins */
@mixin transition($transition) {
	-webkit-transition: $transition;
	-moz-transition:    $transition;
	-ms-transition:     $transition;
	-o-transition:      $transition;
	transition:			$transition;
}

@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}

@function calculateRem($px, $base: $base-font-size) {
	$remSize: $px / $base;
	@return #{$remSize}rem;
}

@mixin transition-property($transition-property) {
	-webkit-transition-property: $transition-property;
	-moz-transition-property:    $transition-property;
	-ms-transition-property:     $transition-property;
	-o-transition-property:      $transition-property;
	transition-property:		 $transition-property;
}

@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	-moz-transition-delay:    $transition-delay;
	-ms-transition-delay:     $transition-delay;
	-o-transition-delay:      $transition-delay;
	transition-delay:		  $transition-delay;
}

@mixin animation($animation) {
	-webkit-animation: 	$animation;
	-moz-animation: 	$animation;
	-ms-animation: 		$animation;
	-o-animation: 		$animation;
	-animation: 		$animation;
}

@mixin animation-name($name) {
	-webkit-animation-name: $name;
	animation-name: $name;
}

@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
	animation-direction: $direction;
}

@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
	-webkit-animation-timing-function: $timing-function;
	animation-timing-function: $timing-function;
}

@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
	animation-iteration-count: $iteration-count;
}

@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
	animation-delay: $delay;
}

@mixin translatey($distance) {
	-webkit-transform: translateY($distance);
	transform: translateY($distance);
}

@mixin translatex($distance) {
	-webkit-transform: translateX($distance);
	transform: translateX($distance);
}

@mixin transform($values) {
	-webkit-transform: $values;
	-ms-transform: $values;
	transform: $values;
}

@mixin rotate($value) {
	-webkit-transform: rotate($value);
	-ms-transform: rotate($value);
	transform: rotate($value);
}

@mixin transform-origin($value) {
	-webkit-transform-origin: $value;
	-moz-transform-origin: $value;
	-ms-transform-origin: $value;
	transform-origin: $value;
}

/* Border radius mixins */
@function meta-unit($number, $unit) {

	@if $unit == "px" {
		@return $number+px
	}
	@if $unit == "%" {
		@return $number+"%"
	}
	@if $unit == "em" {
		@return em($number)+px
	}
	@if $unit == "rem" {
		@return calculateRem($number)
	}
}

/* Border radius mixin */
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
    // behavior: url(/common/scripts/pie/PIE.htc);
}

// tl: top left, tr: top right, br: bottom right, bl: bottom left
@mixin border-radius-all($tl, $tr, $br, $bl, $unit) {
	@if $unit == "%" {
		-webkit-border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
		-moz-border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
		border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
	}

	@if $unit == 'px' {
		-webkit-border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
		-moz-border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
		border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
	}
}


/* Box shadow mixins */
@mixin box-shadow($horizontal, $vertical, $blur, $spread, $colour) {
    -webkit-box-shadow: $horizontal $vertical $blur $spread $colour;
    -moz-box-shadow: $horizontal $vertical $blur $spread $colour;
    -ms-box-shadow: $horizontal $vertical $blur $spread $colour;
    box-shadow: $horizontal $vertical $blur $spread $colour;
}

@mixin box-shadow-none() {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

@mixin inset-box-shadow($horizontal, $vertical, $blur, $spread, $colour) {
    -webkit-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    -moz-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    -ms-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    box-shadow: inset $horizontal $vertical $blur $spread $colour;
}

/* Mixin for keeping mobile and desktop navs separate when they look completely different */
@mixin max-mobile-nav {
	@media only screen and (max-width: #{$max-mobile-nav}) {
		@content;
	}
}

@mixin min-desktop-nav {
	@media only screen and (min-width: #{$max-mobile-nav+1}) {
		@content;
	}
}

/* media query mixins that use breakpoints set in constants.scss */
@mixin max-mobile {
	@media only screen and (max-width: #{$max-mobile}) {
		@content;
	}
}

@mixin min-mobile {
	@media only screen and (min-width: #{$max-mobile}) {
		@content;
	}
}


@mixin min-tablet {
	@media only screen and (min-width: #{$min-tablet}) {
		@content;
	}
}


@mixin max-tablet {
	@media only screen and (max-width: #{$max-tablet}) {
		@content;
	}
}


@mixin min-desktop {
	@media only screen and (min-width: #{$min-desktop}) {
		@content;
	}
}


@mixin small-desktop {
	@media only screen and (max-width: #{$small-desktop}) {
		@content;
	}
}


@mixin max-desktop {
	@media only screen and (max-width: #{$max-desktop}) {
		@content;
	}
}


@mixin min-max-desktop {
	@media only screen and (min-width: #{$min-desktop}) and (max-width: #{$max-desktop}) {
		@content;
	}
}


@mixin min-max-tablet {
	@media only screen and (min-width: #{$min-tablet}) and (max-width: #{$max-tablet}) {
		@content;
	}
}


/* Flexbox mixins */
@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	html.no-flexbox & {
		display: block;
	}
}

@mixin inline-flex() {
	display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;

	html.no-flexbox & {
		display: block;
	}
}

@mixin flex-align($value) {
	-webkit-box-align: $value;
	-ms-flex-align: $value;
	-webkit-align-items: $value;
	align-items: $value;

	@if ($value == center) {
		html.no-flexbox & {
			[class^=span] {
				display: table-cell;
				vertical-align: middle;
				float: none;
				padding: 0 (percentage($margin/$max-width));

				img {
					width: 100%;
				}
			}
		}
	}

	@if ($value == flex-end) {
		-ms-flex-align: end;
	}
}

@mixin flex-wrap($value) {
	-ms-flex-wrap: $value;
	-webkit-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin flex-justify($value) {
	-webkit-box-pack: $value;
	-ms-flex-pack: $value;
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin flex-length($value) {
	-webkit-box-flex: $value;
	-moz-box-flex: $value;
	-webkit-flex: $value;
	-ms-flex: $value;
	flex: $value;
}

@mixin flex-direction($value) {
	-webkit-box-direction: normal;
	-moz-box-direction: normal;
	-ms-flex-direction: $value;
	-webkit-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-order($value) {
	-moz-box-order: $value;
	-ms-flex-order: $value;
	-webkit-order: $value;
	flex-order: $value;
	order: $value;
}


/* Form mixins */
@mixin pointer-events {
	-ms-pointer-events: none;
	pointer-events: none;
}

@mixin placeholder-color($color) {

    &::-webkit-input-placeholder {
       color: $color;
    }

    &:-moz-placeholder { /* Firefox 18- */
       color: $color;
       opacity: 1;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
       color: $color;
       opacity: 1;
    }

    &:-ms-input-placeholder {
       color: $color;
    }

}

@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
  	#{$property}: -webkit-calc(#{$expression});
  	#{$property}: -o-calc(#{$expression});
  	#{$property}: calc(#{$expression});
}

/* Miscellaneous mixins */
@mixin box-sizing($size) {
	-webkit-boz-sizing: $size;
	-moz-box-sizing: $size;
	box-sizing: $size;
}

@mixin font-size($size, $line-height: ($size + 6)) {
	font-size: $size + px;
	font-size: meta-unit($size,rem);
	line-height: meta-unit($line-height,px);
	line-height: meta-unit($line-height,rem);
}

@mixin vertical-gradient($start,$end) {
	background: $end;
	background: -webkit-linear-gradient($start,$end);
	background: linear-gradient($start,$end);
}

@mixin hide-text() {
	text-indent: 110%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin cf() {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@mixin columns($value) {
	-webkit-columns: $value;
	-moz-columns: $value;
	columns: $value;
}

// Allows for 2x images for device ratios 2+
@mixin high-res-bg-image($image, $width, $height){
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// Last child no margin-bottom
@mixin last-child-no-bottom() {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Cancel Transitions
@mixin cancel-transitions() {
	-webkit-transition: none !important;
	-moz-transition:    none !important;
	-ms-transition:     none !important;
	-o-transition:      none !important;
	transition:			none !important;
}

@mixin Din($weight) {
	@if $weight == demi {
		font-family: "din-2014", sans-serif;
		font-weight: 600;
		font-style: normal;
	} @else if $weight == bold {
		font-family: "din-2014", sans-serif;
		font-weight: 700;
		font-style: normal;
	} @else if $weight == bold-italic {
		font-family: "din-2014", sans-serif;
		font-weight: 700;
		font-style: italic;
	}  @else if $weight == extra-bold {
		font-family: "din-2014", sans-serif;
		font-weight: 800;
		font-style: normal;
	} @else if $weight == light {
		font-family: "din-2014", sans-serif;
		font-weight: 300;
		font-style: normal;
	} @else {
		font-family: "din-2014", sans-serif;
		font-weight: 400;
		font-style: normal;
	}
}

@mixin Bree($weight) {
	@if $weight == bold {
		font-family: "bree-serif", serif;
		font-weight: 700;
		font-style: normal;
	} @else if $weight == bold-italic {
		font-family: "bree-serif", serif;
		font-weight: 700;
		font-style: italic;
	}
}

@mixin SourceSans($weight) {
	@if $weight == regular {
		font-family: "source-sans-pro", sans-serif;
		font-weight: 400;
		font-style: normal;
	} @else if $weight == bold {
		font-family: "source-sans-pro", sans-serif;
		font-weight: 700;
		font-style: normal;
	} @else if $weight == semi-bold {
		font-family: "source-sans-pro", sans-serif;
		font-weight: 600;
		font-style: normal;
	}
}
