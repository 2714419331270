html,
body {
	overflow-x: hidden;
}

body {
	position: relative;
	width: 100%;
	left: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
	font-smooth: always;

	@include SourceSans(regular);
	@include transition(left 0.3s ease);
	background-color: #efefef;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		background-color: rgba(2, 47, 58, 0.5);
		@include transition(all .3s ease);
		z-index: 98;
	}

	&.open {
		overflow: hidden;
		position: fixed;
	}

	&.search-active {
		@include max-tablet() {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

* {
	@include box-sizing(border-box);
}

.inline{
	display: inline-block;
}

strong{
	font-weight: bold;
}

ul {
	padding-left: 0;
}

img {
	 max-width: 100%;
	 height: auto;
	 vertical-align: bottom;
}

a {

	@supports (text-decoration-skip-ink: auto){
		text-decoration-skip-ink: auto;
	}
}


// Fixes issues with google maps images not showing correctly
// img[src*="gstatic.com/"], img[src*="googleapis.com/"] {
// 	max-width: none;
// }

/* Cookie Notification styles */
.cookie-notification {
	background-color: $ed-blue-darker;
	color: $white;
	display: none;
	padding: 10px;
	width: 100%;

	@include max-desktop() {
		padding: 10px 20px;
	}

	@include max-mobile() {
		padding: 10px 15px;
	}

	.row {
		@include flex();
		@include flex-align(center);

		// IE9 Fix
		html.no-flexbox & {
			[class^=span] {
				display: block;
				float: left;
				padding: 0;
			}
		}
	}

	p {
		margin: 0;
		line-height: 1.5rem;
	}

	a {
		color: $white;
		text-decoration: underline;
	}

	.close {
		span {
			display: inline-block;
			padding: 10px 10px 10px 11px;
			background-color: $ed-blue-dark;
			cursor: pointer;
			position: relative;
			line-height: normal;

			&:before {
				content: '\f00d';
				font-family: $FontAwesome;
				font-size: 21px;
				color: $white;
			}
		}
	}
}

// Infinity tracking number
.InfinityNumber {
	a {
		color: inherit;
		text-decoration: none;
		cursor: text;
	}
}

.hide-desktop {
	display: none;

	@media only screen and (max-width: 900px) {
		display: block;
	}
}

.hide-mobile {
	@media only screen and (max-width: 900px) {
		display: none;
	}
}

.b-lazy {
	display: block;
	width: 100%;
}

// .b-lazy.b-loaded {
//                opacity: 1;
// }

.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 100;
	display: none;

	.model-window {
		margin: 0 auto;
		width: 100%;
		max-width: 850px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));

		.model-content {
			display: block;
			position: relative;
			width: 100%;

			.model-spacer {
				padding-bottom: 56.25%;
			}

			.close {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				@include transform(translate(0, -150%));
				z-index: 10;
				margin-bottom: 25px;
				cursor: pointer;
				float: right;
				@include Bree(bold-italic);
				color: $white;

				i {
					color: $white;
					margin-left: 5px;
					display: inline-block;
				}
			}

			video,
			iframe {
				display: block;
				width: 100%;
				clear: both;
			}

			iframe {
				border: 0;
				height: auto;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				video {
					width: 100%;
				}
			}
		}
	}

	@include max-tablet() {
		.model-window {
			max-width: 100%;
			.model-content {
				padding: 0;
			}
		}
	}
}

/*** Skip-to-content styles ***/
.skip-link {
	background: $navy;
	color: #fff;
	font-weight: 700;
	top: 0;
	left: 50%;
	padding: 4px;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.3s;
	z-index: 1;
}

.skip-link:focus {
	transform: translateY(0%);
}