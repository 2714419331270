/*** SLIDER / CAROUSEL Styles ***/

.slider {
	opacity: 0;
	@include transition(opacity 0.3s ease);

	&.slick-initialized {
		opacity: 1;
	}

	.slide {

		.row {
			@include flex();
			@include flex-align(center);
		}
	}
}

/* Arrows */
/* Left / Right arrows */
.arrows {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -15px;
	width: 100%;

	@include max-desktop() {
		padding-left: 20px;
		padding-right: 20px;
	}

	.arrow {
		width: 30px;
		height: 30px;
		position: absolute;
		cursor: pointer;

		&:before {
			content: '\f104';
			position: absolute;
			left: 0;
			top: 0;
			font-family: $FontAwesome;
			@include font-size(30,30);
			color: $white;
		}

		&.prev {
			left: 0;
		}

		&.next {
			right: 0;

			&:before {
				content: '\f105';
			}
		}
	}
}

/* DOTS Paging */
.slick-dots {
	position: absolute;
	bottom: 45px;
	width: 100%;
	list-style: none;
	text-align: center;

	li {
		position: relative;
		display: inline-block;
		width: 10px;
		height: 10px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 10px;
			height: 10px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: none;
			@include border-radius(100%);
			outline: none;
			background-color: rgba(255,255,255,0.5);
		}

		&.slick-active {
			button {
				background-color: $white;
			}
		}
	}
}
